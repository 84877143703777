import  React,{ Component } from 'react'; 
import { withTranslation } from 'react-i18next';
import { FormTextField,
    FormTextarea,
    GooglePlacesAutoComplete,
    SelectField
} from './../Common/Helper.js';  
import Reveal from './../Common/Reveal';

import GoogleMapWidget from './../Widgets/GoogleMap';

import axios from 'axios';
import { connect } from 'react-redux';
import { showMessage, hideMessage } from './../../redux/actions.js';
import {ErrorHelperText} from '../Common/Helper'
import exifr from 'exifr'

class AddRequest extends Component{

    constructor(arg) {
        super(arg);
        this.state =   {
            place:null,
            form: {
                location_lat:10.034,
                location_lon : 76.32460
            },
            errors: {}, 
            data:null,
            successMessage: '',
            disabledSubmit: false,
            locationChange:false,
        }
        this.handlePlaceChange = this.handlePlaceChange.bind(this);
        this.validateData = this.validateData.bind(this)
    } 

    componentDidMount(){
        //Passing defult value 'Rescue'
        this.changeFormValue('help_type', 'rescue')
        //Permission for the application to access the location
        if ("geolocation" in navigator) { 
            let that = this

            navigator.geolocation.getCurrentPosition(function(position) {
                that.setState({
                    form : {
                        ...that.state.form,
                        location_lat: position.coords.latitude,
                        location_lon: position.coords.longitude
                    } 
                              
                  });
              });
              
              
          } else {
            console.error("Not Available");
          }   
    } 
    
    locationSelect(lat,lon, geoLocation){ 
        this.state.form.location_lat = lat;
        this.state.form.location_lon = lon;
        let newLocation = {
            lat: lat,
            lon: lon
        };
        if(geoLocation) {
            let location = geoLocation.address_components.filter(item=>item.types.indexOf('sublocality') > -1)
                                                         .map(item=>item.long_name)
                                                         .join(',');
            newLocation.place_id = geoLocation.place_id;
            newLocation.formatted_address = geoLocation.formatted_address;
            newLocation.location = location;
        }
        this.setState({
            setLocation: newLocation,
        });

        
    }



    handlePlaceChange(place){ 
        if (!place.geometry) {
          return;
        }
        this.state.form.address_components  = place.address_components;
        this.setState({
            place:place
        });        
    }


    changeFormValue (name,value) {
        this.state.form[name] = value;
        if(this.state.errors[name]){
            delete this.state.errors[name]
        }
        this.setState({
            form: this.state.form,
            errors:this.state.errors
                }
            )
    }

    handleFileUpload = async (event) => {

        this.state.form['images'] = event.target.files

        if(this.state.errors['images']){
            delete this.state.errors['images']
        }

        //getting the geo details from the uploaded image files
        let {latitude, longitude} = await exifr.gps(event.target.files[0])
        if(latitude , longitude){
            this.setState({
                errors:this.state.errors,
                form : {
                    ...this.state.form,
                    location_lat: latitude,
                    location_lon: longitude,
                    
                } ,
                locationChange:true          
              });
        }
        
        
    }

    validateData(){

        var formData = this.state.form;
        let hasError = false
        let errors = {};
        if(formData.help_type === 'rescue'){
             errors = {};
             if(!formData.title){
                errors['title'] = "This field is required"
            }
            if (!formData.personName) {
                errors['personName'] = "Name is required"
            }
            if (!formData.phoneNumber) {
                errors['phoneNumber'] = "Phone Number is required"
            }
            else if (formData.phoneNumber && formData.phoneNumber.length != 10){
                errors['phoneNumber'] = "Invalid Phone Number"
            }
            if (!formData.help_type) {
                errors['help_type'] = "This field is required"
            }
            if (!formData.peopleCount) {
                errors['peopleCount'] = "This field is required"
            }
            if (!formData.district) {
                errors['district'] = "This field is required"
            }
            if (!formData.address) {
                errors['address'] = "This field is required"
            }
            if(!formData.other_address){
                errors['other_address'] = "This field is required"
            }
            if (Object.keys(errors).length != 0) {
                
                 hasError = true;

            }

        
        //checking errors for Telecom
        }else if(formData.help_type == 'telecom'){
             errors = {};
            if(!formData.title){
                errors['title'] = "This field is required"
            }
            if(!formData.description){
                errors['description'] = "This field is required"
            }
            if(!formData.district){
                errors['district'] = "This field is required"
            }
            if (Object.keys(errors).length != 0) {
                
                hasError = true
            }
        
        //checking errors for Flood
        }else if(formData.help_type == 'flood'){
             errors = {};
            if(!formData.title){
                errors['title'] = "This field is required"
            }
            if(!formData.description){
                errors['description'] = "This field is required"
            }
            if(!formData.district){
                errors['district'] = "This field is required"
            }
            if (formData.images?.length > 5) {
                errors['images'] = "Please upload maximum 5 files"
            }
            if (Object.keys(errors).length != 0) {
                
                hasError = true;
            }
            
            
        }

        return {hasError,errors}
    }
    
    async handleSubmit () {
        const { disabledSubmit } = this.state
        var formData = this.state.form;
        
        
        const validation = this.validateData()
        if(validation.hasError && Object.keys(validation.errors).length != 0){
            this.setState({
                errors: validation.errors
            });
            return
        }else{
            this.setState({
                errors: {}
            });
        }
        

        this.setState({
            disabledSubmit:true
        })

        //Reverse Geocoding
        // eslint-disable-next-line no-undef
         const geocoder = new google.maps.Geocoder();
         const latlng = {
             
             lat: parseFloat(this.state.form.location_lat),
             lng: parseFloat(this.state.form.location_lon),
           };
         const response = await geocoder.geocode({ location: latlng });
         if (response?.results?.[0]) {
            var address = response.results[0].formatted_address
            formData.locationAddress = address //passing the 'address' in the formData as a key to the BE.           
        } 
        
        const postData = new FormData();
        for(const [key,value] of Object.entries(formData)){
            
            if(key === 'images'){
                
                for(const key of Object.keys(formData.images)){
                    const imageItem = formData.images[key]
                    imageItem['timeModified'] = imageItem.lastModified

                    postData.append('images' , imageItem)
                }
            }else{
                postData.append(key,value)
            }
            
        }
        
        axios.post('/api/v1/rescue/add',postData)
        
        
        .then(resp=> {
            resp = resp.data;
            if (!resp.meta.success) { 
                this.props.showMessage('fail', resp.meta.message,1);
            } else {
                this.setState({
                    errors: '',
                    successMessage:resp.meta.message,
                    data:resp.data
                });
            }
            
        });
        
        return false;
    }

    render () {
        const { t } = this.props;
        const { districts } = this.props
        const { disabledSubmit } = this.state
        const mandatoryFields = ['help_type', 'name', 'phone_number', 'district', 'address']
        
      
        let disabledButton = true;
        if( mandatoryFields.every(mItem=> Object.keys(this.state.form).find(item=>item == mItem)) ){
            disabledButton = false
        }
        
        var clsSuccess = 'hidden';
        if (this.state.successMessage != '') {
            return <Reveal  onClose={this.props.hideModal} >
                <div className="w3-green w3-padding-64 w3-center">
                   <h4> {this.state.successMessage}</h4>
                   <h3>{this.state.data.id}</h3>
                </div>
            </Reveal>
        }

        var googlePlace = this.state.setLocation && this.state.setLocation.location ? this.state.setLocation.location : '';

        
        const getFields=()=>{
            return (this.props.fields).map((tItem) => {
                const item1 = tItem?.value?.value?.replace(/\n/gm,'')
                const item2 = item1?.split(',')
                return item2?.map(item => {
                    const splitValue = item?.split(':')

                    return {
                        key:splitValue[0],
                        type:splitValue[1],
                        value:splitValue[2],
                        style:splitValue[3],
                        topKey:tItem.key
                    }
                })
            }).flat()
        }

        const renderFormFields =() => {
            const fieldsArray = getFields()
            const {form} = this.state
            if(!(fieldsArray && fieldsArray.length > 0)){
                return
            }
            const renderFieldArr = fieldsArray.filter(item=>item?.topKey == form.help_type)
            const renderFields = []
            for(const fieldItem of renderFieldArr){
                let field = null
                if(fieldItem.type == 'types'){
                    field = (
                        <div className={`${fieldItem.style} w3-col`}>
                            <FormTextField 
                                isMandatory="true"
                                label={t(`${fieldItem.value}`)}
                                placeholder={fieldItem.value}
                                name={fieldItem.key}
                                inputClass="w3-input w3-border w3-round-large"
                                value = {this.state.form[fieldItem.key]}
                                valueChange={this.changeFormValue.bind(this)}
                                errors = {this.state.errors[fieldItem.key]} 
                            /> 
                        </div>
                    )
                }else if(fieldItem.type == 'Str'){
                    field = (
                        <div className={`${fieldItem.style} w3-col`}>
                            <FormTextField 
                                isMandatory="true"
                                label={t(`${fieldItem.value}`)}
                                placeholder={fieldItem.value}
                                name={fieldItem.key}
                                inputClass="w3-input w3-border w3-round-large"
                                value = {this.state.form[fieldItem.key]}
                                valueChange={this.changeFormValue.bind(this)}
                                errors = {this.state.errors[fieldItem.key]} /> 
                        </div>
                    )
                }else if(fieldItem.type == 'Strs'){
                    field = (
                        <div className={`${fieldItem.style} w3-col`}>
                            <FormTextField 
                                isMandatory="true"
                                label={t(`${fieldItem.value}`)}
                                placeholder={fieldItem.value}
                                name={fieldItem.key}
                                inputClass="w3-input w3-border w3-round-large"
                                value = {this.state.form[fieldItem.key]}
                                valueChange={this.changeFormValue.bind(this)}
                                errors = {this.state.errors[fieldItem.key]} /> 
                        </div>
                    )
                }else if(fieldItem.type == 'Num'){
                    field = (
                        <div className={`${fieldItem.style} w3-col`}>
                            <FormTextField 
                                isMandatory="true"
                                type="number"
                                label={t(`${fieldItem.value}`)}
                                placeholder={fieldItem.value}
                                name={fieldItem.key}
                                inputClass="w3-input w3-border w3-round-large"
                                value = {this.state.form[fieldItem.key]}
                                valueChange={this.changeFormValue.bind(this)}
                                errors = {this.state.errors[fieldItem.key]}/> 
                        </div>
                    )
                }else if(fieldItem.type == 'lt_ln'){
                    field = (
                        <div className={`${fieldItem.style} w3-col`}>  
                        <SelectField
                            label={t(`${fieldItem.value}`)}
                            placeholder={fieldItem.value}
                            name={fieldItem.key}
                            selectClass="w3-select w3-border kf-form-input-select w3-round-large"
                            isMandatory="true"
                            value = {this.state.form[fieldItem.key]}
                            valueChange={this.changeFormValue.bind(this)}
                            errors = {this.state.errors[fieldItem.key]}>
                            <option value="">----</option>
                            {this.props.districts?.map(item => (<option key={item} value={item}>{item}</option>))}
                             
                        </SelectField> 
                        </div>
                    )
                }else if(fieldItem.type == 'file'){
                    field = (
                        <div className={`${fieldItem.style} w3-col`}>
                            <div className=''> 
                                <label>{t("fileUpload")}</label>
                                <input
                                    className='w3-input w3-border w3-round-large' 
                                    type='file' 
                                    name='images' 
                                    onChange={(e)=>{this.handleFileUpload(e)}} 
                                    accept='image/jpeg'
                                    multiple/>
                                    <div style={{color:'grey' , fontStyle:'italic'}}>Maximum 5 uploads</div>
                                    <ErrorHelperText
                                    errors = {this.state.errors.images}/> 
                            </div>  
                        </div>
                    )
                }else if(fieldItem.type == 'StrL'){
                    field = (
                        <div className={`${fieldItem.style} w3-col`}>
                            <FormTextarea
                                label={t(`${fieldItem.value}`)}
                                placeholder={fieldItem.value}
                                name={fieldItem.key}
                                inputClass="w3-input w3-border w3-round-large"
                                isMandatory="true"
                                value = {this.state.form[fieldItem.key]}
                                valueChange={this.changeFormValue.bind(this)}
                                errors = {this.state.errors[fieldItem.key]} />
                        </div>
                    )
                }else if(fieldItem.type == 'NotMandate'){
                    field = (
                        <div className={`${fieldItem.style} w3-col`}>
                            <FormTextField 
                                label={t(`${fieldItem.value}`)}
                                placeholder={fieldItem.value}
                                name={fieldItem.key}
                                inputClass="w3-input w3-border w3-round-large"
                                value = {this.state.form[fieldItem.key]}
                                valueChange={this.changeFormValue.bind(this)} /> 
                        </div>
                    )
                }
                if(field){
                    renderFields.push(field)
                }
            }
            return renderFields
        }
    
        return (
            <Reveal  onClose={this.props.hideModal} modalClass='request-addmodal'>
               <div className="w3-container ">   
                    <h4 className=" w3-center w3-margin">
                        {t("createIssue")}
                    </h4> 
                    <form className="w3-row-padding">

                        <div className="l12 s12 w3-col">   
                        <SelectField
                            label= {t("whatHelpYouWant")}
                            placeholder="What Help you want"
                            name="help_type"
                            selectClass="w3-select w3-border kf-form-input-select w3-round-large"
                            isMandatory="true"
                            value = {this.state.form.help_type}
                            valueChange={this.changeFormValue.bind(this)}
                            errors = {this.state.errors.help_type}>
                            
                            {(this.props.issuetypes || []).map((item, idx) => {
                                return <option key={idx} value={item.value}>{t(item.label)}</option>
                            })} 
                        </SelectField> 
                        </div>
                        
                        {this.state.form.help_type && renderFormFields()} 

                        <div className="w3-col l12 s12 " id="location" style={{marginTop:"20px"}}>
                            <label className="w3-text-black">{t("searchAndMarkLocation") + " Search and mark your location)" }</label> 
                            <GooglePlacesAutoComplete
                                albumLocation={googlePlace}
                                disabled={this.state.locationChange}
                                onPlaceChange={place => this.handlePlaceChange(place)}
                                placeholder = "Location"
                            />
                        </div>
                        
                        <div className="m12 s12 w3-col " >
                               <GoogleMapWidget mapStyle={{height: '250px'}} 
                               place={this.state.place}
                               disabled={this.state.locationChange}
                               mapId='google-map-rescue'
                               locationSelect={this.locationSelect.bind(this)}
                               location={{
                                lat: this.state.form.location_lat,
                                lng: this.state.form.location_lon
                               }}/>    
                        </div>
                        
                      
                        <div className="w3-panel m12 s12 w3-col">
                            <div className="w3-right">
                                <button type="button" className="w3-button w3-dark-grey" onClick={this.props.hideModal}>{t("cancel")}</button>
                                <button type="button" className="w3-button w3-margin-left" style={{backgroundColor:'#fcc512'}} disabled = {disabledSubmit} onClick={this.handleSubmit.bind(this)}>{t("submit")}</button>
                            </div>
                        </div>
                    </form>
                </div>   
            </Reveal>
        )
    }
} 


function mapStateToProps(state) {
    return {
        requestTypes:state.requestTypeList,
        districts: state.districts,
        issuetypes: state.issueTypes,
        fields: state.fields
    }
}
export default connect(mapStateToProps, { 
    showMessage,
    hideMessage
})(withTranslation()(AddRequest));
