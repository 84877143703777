import axios from "axios";
import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { showMessage } from '../../../../src/redux/actions'
import AuthBg from "./images/footer.png";
import GoogleLogo from '../../../images/GoogleLogo.png'
import FbLogo from '../../../images/FbLogo.png'

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.otpInput1 = React.createRef();
        this.otpInput2 = React.createRef();
        this.otpInput3 = React.createRef();
        this.otpInput4 = React.createRef();
        this.state = {
            sendotp: false,
            showOtpLogin: false,
            input: {
                phoneNumber: '',
                name: '',
                passwordToken: '',

            },
            otp: {},
            timer: 20,
            userExists: false,
            currentScreen: 'auth',
            forgotPassword: false,
            passwordReset: false,
            confirmPassword: '',
            showPassword: true
        }
    }

    runTimer() {
        this.interval = setInterval(() => {
            this.setState({
                timer: this.state.timer - 1
            }, () => {
                if (this.state.timer == 0) {
                    clearInterval(this.interval);
                }
            })
        }, 1000);
    }
    
    changeInputValue = (event) => {
        const { name, value } = event.target;
        this.setState({
            input: {
                ...this.state.input,
                [name]: value
            },
        },
            // () => {
            //     if(name === "phoneNumber"){
            //         const phoneNoLength = this.state.input.phoneNumber.length;
            //         if(phoneNoLength == 10) {
            //             return this.getUserNameByPhone();
            //         };

            //         this.setState({
            //             userExists: false
            //         })
            //     } 

            // }
        )


    }

    onOtpChange = (event, idx) => {
        const { name, value } = event.target;
        this.setState({
            otp: {
                ...this.state.otp,
                [idx]: value
            }
        }, () => {
            //fous the next input
            const inputIdxToFocus = idx + 1;
            const inputRef = this[`otpInput${inputIdxToFocus}`];
            if (inputRef && value) {
                inputRef.current.focus();
            }
        })
    }

    resendOtp = () => {
        if (this.state.timer) {
            return;
        }
        this.sendOtp();
        this.setState({
            timer: 20
        })
    }

    sendOtp = () => {

        //alert("OTP has been send to your mobile")
        var userEntry = this.state.input

        axios.post('auth/send-otp', userEntry)
            .then(resp => {
                resp = resp.data;

                if (!resp.meta.success) {
                    this.props.showMessage('fail', resp.data.meta.message);
                    return
                } else {
                    this.props.showMessage('success', 'OTP has been send to your mobile.');
                    this.setState({ sendotp: true }, this.runTimer)

                }
            });

    }


    verifyOtp = () => {

        var otpenter = this.state.otp
        var array = Object.keys(otpenter)
            .map(function (key) {
                return otpenter[key];
            });

        var otpstring = array.join('');

        axios.post('/auth/verify-otp', { otp: otpstring, phoneNumber: this.state.input.phoneNumber })
            .then(resp => {
                resp = resp.data;

                if (!resp.meta.success) {
                    this.props.showMessage('fail', 'Incorrect OTP');
                } else {
                    this.props.showMessage('success', 'Successfully verified');
                    window.location.replace("/dashboard");
                    // this.props.history.replace("/dashboard");
                }
            });
    }

    showOtpLoginForm = () => {
        this.setState({
            showOtpLogin: true
        })
    }

    checkUserExist = () => {


        axios.post('/auth/check-user', { phoneNumber: this.state.input.phoneNumber })
            .then(resp => {
                resp = resp.data

                if (!resp.meta.success) {
                    this.setState({
                        currentScreen: 'register'
                    })

                } else {
                    this.setState({
                        currentScreen: 'login',

                    })

                }
            })

    }

    login = () => {
        axios.post('/auth/login-user', { passwordToken: this.state.input.passwordToken, phoneNumber: this.state.input.phoneNumber })
            .then(resp => {

                resp = resp.data

                if (!resp.meta.success) {
                    this.props.showMessage('fail', 'Incorrect Password');
                } else {
                    this.props.showMessage('success', 'Login Succesfull');
                    window.location.replace("/app");
                    // this.props.history.replace("/dashboard");
                }
            })
    }

    onClickForgotPassword = () => {
        this.setState({
            forgotPassword: true
        })
    }

    onChangeConfirmPassword = (event) => {
        const { name, value } = event.target;
        this.setState({
            confirmPassword: event.target.value
        })
    }

    resetPassword = () => {
        if (this.state.input.passwordToken == this.state.confirmPassword) {
            axios.post('/auth/reset-password', { passwordToken: this.state.input.passwordToken, phoneNumber: this.state.input.phoneNumber })
                .then(resp => {
                    resp = resp.data

                    if (!resp.meta.success) {
                        this.props.showMessage('fail', resp.data.meta.message);
                        return
                    } else {
                        this.props.showMessage('success', 'Password Reset Successfully, Please click on the login button for login');
                        window.location.replace("/app");

                    }
                })
        } else {
            this.props.showMessage('fail', 'Passwords are not matching')
        }

    }

    onclickPasswordReset = () => {
        this.setState({
            passwordReset: true
        })
    }

    showPasswordInput = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }
   
    render() {
        const {showGoogleAuth, showFacebookAuth} = this.props
        const { phoneNumber, name, passwordToken} = this.state.input;
        const { 
            otp, 
            userExists, 
            sendotp, 
            timer, 
            currentScreen, 
            forgotPassword, 
            passwordReset, 
            confirmPassword,
            showPassword } = this.state;
        const sendOtpDisabled = !phoneNumber || !name || phoneNumber.length < 10 || passwordToken.length < 8 || passwordToken != confirmPassword;
        const sendOtpDisabledForgotPw = !phoneNumber
        const nextDisable = phoneNumber.length < 10 || phoneNumber.length > 10
        const loginDisable = passwordToken.length < 8
        const resetPwDisable = !passwordToken || !confirmPassword || passwordToken.length < 8 || passwordToken != confirmPassword
        const values = Object.values(otp)
        const result = (values.filter(num => {
            if (num) {
                return true;
            }
            return false;
        })).length < 4;
        const enterNameDisabled = userExists;

        let mandatoryField = (<span className="w3-text-red">*</span>)

        let Or = null
        if(showGoogleAuth || showFacebookAuth){
            Or = <div className="w3-row" style={{position: 'relative' , bottom: '-12px'}}>
                    <hr className="w3-col" style={{width: '40%'}}></hr>
                    <p className="w3-col w3-center" style={{width: '20%'}}>Or</p>
                    <hr className="w3-col" style={{width: '40%'}}></hr>
                </div>
        }

        let facebookLogin = null
        if(showFacebookAuth){
            facebookLogin = <a className="w3-border w3-round w3-button fb-g-button" style={{ marginBottom: '5px', marginTop: '-12px' }} href="/auth/facebook">
                                <div className="w3-row">
                                    <div className="w3-col s2 l4 w3-third">
                                        <img src={FbLogo}></img>
                                    </div>
                                    <div className="w3-col s10 l8 w3-twothird">
                                        <div className="w3-left">Continue with Facebook</div>
                                    </div>
                                </div>
                            </a>
        }

        let googleLogin = null
        if(showGoogleAuth){
            googleLogin = <a className="w3-border w3-round w3-button fb-g-button " style={{ marginBottom: '15px' }} disabled='false' href="/auth/google">
                            <div className="w3-row">
                                <div className="w3-col s2 l4 w3-third">
                                    <img src={GoogleLogo}></img>
                                </div>
                                <div className="w3-col s10 l8 w3-twothird">
                                    <div className="w3-left">Continue with Google</div>
                                </div>
                            </div>
                        </a>
        }

        let authContent = (
            <>
                <h6 className="label">Login</h6>
                <div><h6 className="label-2">Enter Phone Number{mandatoryField}</h6></div>
                <div className="phone-input w3-row">
                    <div className="w3-col m2 s12">
                        <select className="authcontent-dropdown">
                            <option>+91</option>
                        </select>
                    </div>
                    <div className="w3-col m10 s12">
                        <input name="phoneNumber" value={phoneNumber} onChange={this.changeInputValue} type='number' className="phone-input-entry" ></input>
                    </div>
                </div>
                <div className="w3-margin-top">
                    <button disabled={nextDisable} onClick={this.checkUserExist} className="button-next w3-button" style={{marginTop:'-2%'}}>Next</button>
                </div>
                <div style={{width: '100%', position: 'absolute', bottom: '5%'}}> 
                    {Or}
                    <div className="w3-margin-top">
                        {facebookLogin}
                        {googleLogin}
                    </div>
                </div>
            </>
        );

        if (currentScreen === 'login') {
            authContent = (

                <div className="">
                    <h6 className="label">Login</h6>
                    <h6 className="label-2">Enter Password{mandatoryField}</h6>
                    <div>
                        <input className="password-input" name="passwordToken" value={passwordToken} onChange={this.changeInputValue} minLength={8} type={showPassword ? 'password' : 'text'}></input>
                    </div>
                    <div className="w3-row">
                        <a style={{ cursor: 'pointer', color: "#707070" }} onClick={this.onClickForgotPassword}>Forgot password?</a>
                        <div className="w3-right" style={{ color: '#707070' }}>Show Password
                        <span style={{ marginLeft: '2px' }} >
                            <input onChange={this.showPasswordInput} style={{ height: '11px' }} type='checkbox'></input>
                        </span>
                    </div>
                    </div>    
                    <div style={{width: '100%', position: 'absolute', bottom: '10%'}}> 
                        <button disabled={loginDisable} onClick={this.login} className='login-button w3-button'>Login</button>
                    </div>
                </div>
            )
        }

        if (forgotPassword) {
            authContent = (
                <div className="">
                    <h6 className="label">Forgot Password</h6>
                    <div><h6 className="label-2">Phone Number{mandatoryField}</h6></div>
                    <div className="phone-input w3-row">
                        <div className="w3-col m2 s12">
                            <select className="authcontent-dropdown">
                                <option>+91</option>
                            </select>
                        </div>
                        <div className="w3-col m10 s12">
                            <input  name="phoneNumber" value={phoneNumber} onChange={this.changeInputValue} maxLength={10} className="phone-input-entry" ></input>
                        </div>
                    </div>
                    
                    <button disabled={sendOtpDisabledForgotPw} onClick={this.onclickPasswordReset} className='login-button w3-button' style={{position:'absolute' , bottom:'10%'}}>Next</button>
                    
                </div>
            )
        }

        if (currentScreen === 'register') {
            authContent = (

                <div className="">
                    <h6 className="label">Register</h6>
                    <h6 style={{ color: '#B69212' }} className="label">This number is not registered!</h6>
                    <div><h6 style={{ color: '#393939' }}>Enter Phone Number{mandatoryField}</h6></div>
                    <div className="phone-input w3-row">
                        <div className="w3-col m2 s12">
                            <select className="authcontent-dropdown">
                                <option>+91</option>
                            </select>
                        </div>
                        <div className="w3-col m10 s12">
                            <input name="phoneNumber" value={phoneNumber} onChange={this.changeInputValue} maxLength={10} className="phone-input-entry w3-border" style={{ height: '41px' }}></input>
                        </div>
                    </div>
                    <div><h6 style={{ color: '#393939' }}>Enter Your Name{mandatoryField}</h6></div>
                    <input disabled={enterNameDisabled} name="name" value={name} onChange={this.changeInputValue} className="w3-border phone-input-entry" style={{ marginLeft: '0px' }} placeholder="Enter your Name"></input>
                    <div><h6 className="w3-margin-top" style={{ color: '#393939' }}>Create Password{mandatoryField}</h6></div>
                    <input placeholder="Minimum 8 Characters" name='passwordToken' value={passwordToken} type='password' className="w3-border password-input" onChange={this.changeInputValue} minLength={8}></input>
                    <div><h6 style={{ color: '#393939' }}>Confirm Password{mandatoryField}</h6></div>
                    <div><input placeholder="Minimum 8 characters" type='password' value={confirmPassword} onChange={this.onChangeConfirmPassword} minLength={8} className="w3-border phone-input-entry"></input></div>
                    <div style={{width: '100%', position: 'absolute', bottom: '10%'}}> 
                        <button disabled={sendOtpDisabled} onClick={this.sendOtp} className='register-button w3-button'>Send OTP</button>
                    </div>
                </div>
            )
        }

        if (passwordReset) {
            const passwordToken = null
            authContent = (
                <>
                    <div>
                    <h6 className="label">Reset Password</h6> 
                    <h6 style={{ color: '#393939' }}>Create New Password{mandatoryField}</h6>
                        <input placeholder="Minimum 8 characters" name='passwordToken' value={passwordToken} type='password' onChange={this.changeInputValue} minLength={8} className="w3-border phone-input-entry"></input>
                    </div>
                    <div><h6 style={{ color: '#393939' }}>Confirm Password{mandatoryField}</h6></div>
                    <div><input placeholder="Minimum 8 characters" type='password' value={confirmPassword} onChange={this.onChangeConfirmPassword} minLength={8} className="w3-border phone-input-entry"></input></div>
                    <div className="w3-center" style={{width: '100%', position: 'absolute', bottom: '10%'}}> 
                        <button disabled={resetPwDisable} className="register-button w3-button" onClick={this.resetPassword}>Reset Password</button>
                    </div>
                </>
            )
        }

        if (sendotp) {
            authContent = (
                <div className="">
                    <h6 className="label">Register</h6>
                    <div><h6 style={{ color: '#393939', fontSize: '13px' }}>Please enter OTP number send to {phoneNumber} to register</h6></div>
                    <div><h6 style={{ color: '#393939' }}>Enter OTP</h6></div>
                    <div className="w3-center">
                        <input ref={this.otpInput1} onChange={(e) => this.onOtpChange(e, 1)} maxLength={1} style={{ marginLeft: '0px' }} className="w3-border verify-otp"  ></input>
                        <input ref={this.otpInput2} onChange={(e) => this.onOtpChange(e, 2)} maxLength={1} className=" w3-border verify-otp" ></input>
                        <input ref={this.otpInput3} onChange={(e) => this.onOtpChange(e, 3)} maxLength={1} className=" w3-border verify-otp" ></input>
                        <input ref={this.otpInput4} onChange={(e) => this.onOtpChange(e, 4)} maxLength={1} style={{ marginRight: '0px' }} className=" w3-border verify-otp" ></input>
                    </div>
                    <button disabled={result} onClick={this.verifyOtp} className='login-button w3-button' style={{marginTop:'3%'}}>Verify OTP</button>
                    <div className="w3-center">
                        <a className=" resentOtp" style={timer ? { cursor: 'none', color: "lightgrey" } : {}} onClick={this.resendOtp} >Resend OTP {timer ? `(${timer}s)` : ''}</a><span></span>
                    </div>
                </div>
            )
        }


        return (
            <div className="w3-modal w3-show">
                <div className="w3-modal-content auth-modal">
                    <button className="w3-right w3-large close-button" 
                        onClick={this.props.onClose}  type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="w3-row w3-black w3-round full-height">
                        <div className="w3-col m6 s4 auth-left-section full-height">
                            <div style={{ position: 'relative' }} className="full-height">
                                <div style={{ position: 'absolute', bottom: '10%', padding: '10px' }}>
                                    <img src={AuthBg}></img>
                                    <div className="w3-row" >
                                        <div className=""style={{fontSize:'8px', color:'#707070'}}>Rescuehands.in @ 2023 .
                                        <a target="_blank" style={{textDecoration:'none'}} href='/terms'> Terms . </a>
                                        <a target="_blank" style={{textDecoration:'none'}} href='/privacy'>Policy</a>
                                        </div>
                                        
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="w3-col m6 s8 full-height">
                            <div className="w3-white w3-round-large w3-margin" style={{height: "85%"}}>
                                <div className="w3-padding full-height">
                                    <div className="full-height" style={{ position: 'relative'}}>
                                        {authContent}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        showGoogleAuth: state.showGoogleAuth,
        showFacebookAuth: state.showFacebookAuth
    }
}

export default withRouter(connect(mapStateToProps,
        { showMessage },
    )(Auth)
);

