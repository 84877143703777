import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppMessage from "./Common/AppMessage";
import StatusWidget from "./Widgets/Status";
import Header from "./Common/Header";
import RequestLister from "./Request/Lister";
import { NavLink } from "react-router-dom";
import Leaderboard from "./Widgets/Leaderboard.js";
import { showModal } from "./../redux/actions.js";
import RequestPanel from "./Request/Panel";
import { withTranslation } from "react-i18next";
import { Footer } from "./Common/Footer";

class Home extends Component {
  constructor(arg) {
    super(arg);
    this.state = {
      modalContent: null,
      status: [],
    };
  }

  componentDidMount() {
    if (this.props.match.params.label) {
      this.props.showModal(this.props.match.params.label);
    }
  }

  renderHome() {
    const { t } = this.props;

    const { homepageDesc, howToUseContent } = this.props;
    const listItems = <div><li>{t("howToSolveProblemLine1")}</li>
      <li>{t("howToSolveProblemLine2")}</li>
      <li>{t("howToSolveProblemLine3")}</li>
      <li>{t("howToSolveProblemLine4")}</li>
    </div>

    return (
      <div className="w3-content ">
        {this.state.modalContent}
        <div className="w3-row">
          <div className="w3-twothird">
            <div className="w3-padding" style={{ width: "100%", height: "200px" }} >
              <h4 style={{ fontSize: "55px" }}>{homepageDesc}!</h4>
            </div>
            <div className="flex-container">
              <div className="w3-col m6 s12 w3-padding">
                <div className="w3-padding  w3-round-large w3-pale-red info-box">
                  <h4>{t("reportIssueMain")}</h4>
                  <h6>{t("reportIssueSub")}</h6>
                  <div className="w3-center">
                    <button
                      className=" w3-padding-small w3-round w3-button"
                      style={{ backgroundColor: "#E76241", color: "white" }}
                      onClick={(e) => this.props.showModal("request")}
                    >
                      {t("requestHelp")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="w3-col m6 s12  w3-padding">
                <div className="w3-padding w3-round-large w3-pale-blue info-box"  >
                  <h4>{t("provideHelpMain")}</h4>
                  <h6> {t("provideHelpSub")}</h6>
                  <div className="w3-center">
                    <button
                      className="w3-padding-small w3-round w3-button w3-blue"
                      style={{ backgroundColor: "#4544FD", color: "white" }}
                      onClick={(e) => this.props.showModal("help_center")}
                    >
                      {t("provideHelp")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-container">
              <div className="w3-col m6 s12 w3-padding">
                <div className="w3-padding w3-round-large w3-pale-blue info-box"  >
                  <h4> {t("otherSafeMain")} </h4>
                  <h6> {t("othersSafeSub")} </h6>
                  <div className="w3-center">
                    <button
                      className=" w3-padding-small w3-round w3-button w3-blue"
                      onClick={(e) => this.props.showModal("mark_other_safe")}
                    >
                      {t("markPeopleWhomYouKnowAreSafe")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="w3-col m6 s12 w3-padding">
                <div className="w3-padding w3-round-large w3-pale-green info-box "  >
                  <h4 >{t("yourSafeMain")}</h4>
                  <h6> {t("yourSafeSub")}</h6>
                  <div className="w3-center">
                    <button
                      className=" w3-padding-small w3-round w3-button  w3-green"
                      onClick={(e) => this.props.showModal("mark_safe")}
                    >  {t("markyourselvessafe")}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w3-third ">
            <div className="w3-row w3-margin-bottom w3-round-large" style={{ height: "50%" }} >
              <Leaderboard />
            </div>
            <div
              className="w3-row  w3-round-large"
              style={{ backgroundColor: "white", fontSize: "12px" }}
            >
              <h6 className="w3-padding" style={{ color: "#6e6e6e" }}>
                {t("howWeSolveProblemTitle")}
              </h6>
              <ul>
                {listItems}
                <li>
                  <a
                    style={{ color: "blue" }}
                    target="_blank"
                    href={howToUseContent}
                  >
                    {t("howToUseRH")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <StatusWidget />
        </div>
      </div>
    );
  }

  render() {
    const { search, page } = this.props;
    let content = null;
    if (search != "") {
      content = (
        <div className="w3-panel">
          <RequestLister status="all" page={page} />
        </div>
      );
    } else {
      content = this.renderHome();
    }
    return (
      <div>
        <AppMessage />
        <Header />
        <div className="w3-margin-bottom" style={{ minHeight: "90vh" }}>

          <div className="">
            {content}
          </div>
        </div>
        <Footer />
        <template id="form_success">
          <div className="w3-center w3-container">
            <h4>Thank you, we will get back to you shortly.</h4>
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
        </template>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    search: state.searchText,
    homePage: state.homePage,
    homepageDesc: state.homepageDesc,
    homePageCtnt: state.homePageCtnt,
    howToUseContent: state.howToUseContent,
  };
}

export default connect(mapStateToProps, {
  showModal,
})(withRouter(withTranslation()(Home)));
