import  React,{ Component } from 'react'; 
import {NavLink,withRouter} from 'react-router-dom';
import { connect } from 'react-redux'; 
import { showMessage, hideMessage,showModal } from './../redux/actions.js';   
import AppMessage from './Common/AppMessage.js'; 
import OneRequest from './Request/OneRequest';
import Header from './Common/Header';
import RequestPanel from './Request/Panel';
import RequestLister from './Request/Lister';
import Status from './Widgets/Status.js';
import Leaderboard from './Widgets/Leaderboard.js';
import Activity from './Widgets/Activity.js'; 
import SubHeader from './Common/SubHeader.js';
import { withTranslation } from 'react-i18next';

 class DashboardInfoComponent extends  Component {

    render() {

        const {t} = this.props
        return <div className="w3-container kf-manage-container"> 
            <div className="w3-row-padding ">
                    <div className="w3-col l7 s12">
                        <h3>{t("whatToDo")} </h3>
                        <ul className="w3-ul">
                            <li><h4>{t("theWorkflow")}</h4>
                                <ul className="w3-ul">
                                    <ol>1.{t("theWorkflow1")}</ol>
                                    <ol>2.{t("theWorkflow2")}  </ol>
                                    <ol>2.{t("theWorkflow3")}</ol>
                                    <ol>3.{t("theWorkflow4")}</ol>
                                    <ol>5.{t("theWorkflow5")}</ol>
                                    <ol>6.{t("theWorkflow6")}</ol>
                                </ul>
                            </li>
                            <li>{t("theWorkflowDesc11")}{t("theWorkflowDesc12")}{t("theWorkflowDesc13")}{t("theWorkflowDesc14")}</li>
                            <li>{t("theWorkflowDesc2")}</li>
                            <li>{t("theWorkflowDesc3")}</li>
                            <li>{t("theWorkflowDesc4")}</li>
                            <li><b>{t("theWorkflowDesc5")}</b> </li>
                            <li><a target="_blank" href="https://docs.google.com/document/d/1jM_hdHgP-kxkzOtxl0n8mUGY4EzP6di2NyHIEvFp8YI/edit" >How To Use</a> , <a target="_blank"  href="https://docs.google.com/document/d/1oMs4JwHMDS9agR3voVpeGL0SMhfE35fETYNbD5rZLN8/edit">ഉപയോഗക്രമം </a></li>
                        </ul>
                        <NavLink  to="/requests/duplicates" 
                            className="w3-button w3-round  w3-block w3-blue w3-margin-bottom">
                            {t("checkIfDuplicatesMarkedCorrectly")}
                        </NavLink>  
                        <NavLink  to="/request/one_item/new" 
                            className="w3-button w3-hide w3-round  w3-block w3-green w3-margin-bottom">
                            Start working on a Help request 
                        </NavLink>   
                        <Status/>
                    </div>
                    <div className="w3-col l5 s12 ">
                        <Activity/>
                        <Leaderboard /> 
                        <div className="w3-right-align w3-section"><a className="" href="/logout">{t("logOut")}</a></div>
                    </div>
                </div> 
        </div>
    }
}

const DashboardInfo = (withTranslation()(DashboardInfoComponent))
 

class Dashboard extends Component{
    constructor(arg) {
        super(arg);
        this.state =   {
            data:null,
            search:'',
            mobileMenu:'w3-hide', 
            form: {},
            errors: {}, 
            successMessage: ''
        } 
    } 
    componentDidMount(){
        const {requestId} = this.props.match.params; 
        if (requestId){ 
            this.props.showModal('update_request',{
                id:requestId
            });
            this.props.history.replace('/dashboard');
        }
    }
    componentWillReceiveProps(nextProps){ 
        if (this.props.match.params.requestId == nextProps.match.params.requestId){
            return;
        }
        const {requestId} = nextProps.match.params;
        if (requestId){
            this.props.showModal('update_request',{
                id:requestId
            });
            this.props.history.replace('/dashboard');
        }
    }

    togggleMobile(){
        this.setState({mobileMenu:(this.state.mobileMenu == 'w3-hide')? 'w3-show' : 'w3-hide'})
    }
    render () {
        let { page=1, status='all',requestId } = this.props.match.params; 
        let content = null;  
        if (this.props.match.url.indexOf('dashboard') >= 0  ){
            content = <DashboardInfo />
            if(this.props.searchText != ""){
                content = <div className="w3-panel"> <RequestLister /></div>
            }  
        } else if (status == 'one_item'){
            content = <OneRequest status={page} authUser={this.props.authUser}/> 
        } else {
            content = <RequestPanel page={page}  status={status}  /> 
        }
        return (
            <div >
                <AppMessage />
                <Header />
                {this.state.modal}
                {content}
            </div>
        )
    }
} 
function mapStateToProps(state) {
    return {
        authUser: state.authUser,
        lastUpdateTime:state.lastUpdateTime, 
        districtMap:state.districtMap,
        searchText:state.searchText,
    }
}
export default withRouter(connect(mapStateToProps, { 
    showMessage,
    hideMessage,
    showModal
})(withTranslation()(Dashboard)));
