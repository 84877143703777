function apps( state = {isFetching: false, apps: []}, action) {
  switch (action.type) {
    case "MESSAGE_STATUS":
        return Object.assign({}, state, { appMessage: action.value });
    case "SESSION_USER":
        return Object.assign({},state , {authUser: action.data});
    case 'SEARCH_TEXT':
      return Object.assign({},state,{searchText:action.value}); 
    case 'MODAL_UPDATE':
      return Object.assign({},state,{
        modalInfo:{
          name:action.name,
          data:action.data
        }
      }); 

    case 'REQUEST_FILTER_STATS': 
      state = Object.assign({},state,{
        requestFilterStats:action.data
      }); 
      return state;
    case 'SET_DATA':
      state = Object.assign({}, state, { [action.key]: action.value });
      return state;
    default:
      return state
  }
}

export default apps
