const translationsEn = {
    "requestHelp" : "Request Help",
    "provideHelp" : "Provide help",
    "markPeopleWhomYouKnowAreSafe" : "Mark People whom you know are safe",
    "markyourselvessafe" : "Mark Yourselves Safe",
    'rescue':'Rescue',
        
    'food_and_water':'Food & Water ready to serve',
        
    'food_rawmaterial':'Food Raw materials',
        
    'medicine_blankets':'Medicine Supply',
        
    'home_items':'Home Items',
        
    'cleaning_materials':'Cleaning Materials',
        
    'clothing':'Clothing',
        
    'electrical_works':'Electrical Works',
        
    'pest_animal_control':'Pests Animal Control',
        
    'home_lost':'Lost Home',
        
    'kitchen_utencils':'Kitchen Utencils - Gas',
        
    'find_missing':'Find Missing people',
           
    'other':'Others'
        
    

}



export default translationsEn;