/* global google */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import qs from 'query-string';
import { NavLink, Link, withRouter } from 'react-router-dom';

import ProviderFilter from './Filter';
import Header from '../Common/Header';
import { Spinner } from './../Common/Helper';
import Reveal from './../Common/Reveal';
import { Footer } from '../Common/Footer';
import { withTranslation } from 'react-i18next';

class ServiceProviderComponent extends Component {

    render() {
        const { item,t } = this.props;

        return (
            <div key={`item_${item.id}`} className="w3-col l6 w3-medium w3-padding" style={{ height: '100%' }}>
                <div className=" w3-white w3-padding w3-small  w3-round" style={{ position: 'relative' }}>
                    <strong className="w3-text-gray">{t("contactName")}:</strong> {item.contactName}<br />
                    <strong className="w3-text-gray">{t("phone")}:</strong> {item.phoneNumber}<br />
                    <strong className="w3-text-gray">{t("serviceType")}:</strong> <span style={{ textTransform: 'capitalize' }}>{item.type.replace(/_/g, ' ')}</span><br />
                    <strong className="w3-text-gray">{t("District")}:</strong> {item.district}<br />
                    <strong className="w3-text-gray">{t("peopleCount")}:</strong> {item.peopleCount}<br />
                    <strong className="w3-text-gray">{t("created")}:</strong> {moment(item.createdAt).fromNow()}<br />
                    <strong className="w3-text-gray">{t("serviceEndDate")}:</strong> {
                    item.serviceEndDate ? moment(item.serviceEndDate).format('YYYY-MM-DD'):'NA'}<br />
                    <div>
                        <button className="w3-display-bottomright w3-small  w3-round w3-button w3-blue w3-padding-small" style={{ margin: "2px" }}
                            onClick={e => this.props.showDetailModal(item)}>{t("viewDetails")}</button>
                    </div>
                </div>
            </div>
        );
    }
}

const ServiceProvider = (withTranslation()(ServiceProviderComponent))

export const Paginator = ({ page, status, data }) => {
    page = page - 10;
    const pages = [];
    if (page < 1) {
        page = 1;
    }
    const lastPage = data.page_max;
    for (var idx = page; idx < page + 18 && idx < lastPage; idx++) {
        pages.push(idx);
    }

    if (data.total < data.per_page) {
        return <div></div>
    }

    return <div className="w3-bar">
        <NavLink to={`/providers/list/1`} className="w3-button">&laquo;</NavLink>
        {
            pages.map(page => <NavLink key={`page_${page}`} to={`/providers/list/${page}`}
                className="w3-button">{page}</NavLink>)
        }
        <NavLink to={`/providers/list/${lastPage}`} className="w3-button">&raquo;</NavLink>
    </div>
}


class ServiceProviderDetailComponent extends Component {

    componentDidMount() {
        const { item } = this.props;
        if (item.latLng && item.latLng.coordinates && item.latLng.coordinates.length == 2) {
            this.initialiseGMap(item.latLng.coordinates[0], item.latLng.coordinates[1]);
        }
    }

    initialiseGMap(latitude, longitude) {
        if (!this.map) {
            this.map = new google.maps.Map(document.getElementById('provider-google-map-details'), {
                center: { lat: latitude, lng: longitude },
                zoom: 14,
                zoomControl: true,
                scaleControl: true,
                styles: [{
                    featureType: 'poi',
                    stylers: [{ visibility: 'off' }]  // Turn off points of interest.
                }, {
                    featureType: 'transit.station',
                    stylers: [{ visibility: 'off' }]  // Turn off bus stations, train stations, etc.
                }],
            });
        }
        this.marker = new google.maps.Marker({
            position: { lat: latitude, lng: longitude },
            map: this.map
        });
        this.map.setCenter({ lat: latitude, lng: longitude });
        this.marker.setVisible(true);
    }

    render() {
        const { item, t } = this.props;

        let leftCont = (
            <div className="w3-col l6 s12">
                <div className="w3-padding-small"><strong className="w3-text-gray">{t("contactName")}:</strong> {item.contactName}</div>
                <div className="w3-padding-small"><strong className="w3-text-gray">{t("phone")}:</strong> <span>
                    <a className="w3-buttom w3-tag w3-redice w3-round w3-blue" href={`tel:${item.phoneNumber}`}>{item.phoneNumber}</a>
                </span>
                </div>
                <div className="w3-padding-small"><strong className="w3-text-gray">{t("serviceType")}:</strong> <span style={{ textTransform: 'capitalize' }}>{item.type.replace(/_/g, ' ')}</span></div>
                <div className="w3-padding-small"><strong className="w3-text-gray">{t("District")}:</strong> {item.district}</div>

                <div className="w3-padding-small"><strong className="w3-text-gray">{t("peopleCount")}:</strong> {item.peopleCount}</div>
                <div className="w3-padding-small"><strong className="w3-text-gray">{t("MoreInfo")}:</strong>
                    {(item.information) ?
                        <div className="w3-border w3-padding-small w3-padding-16 w3-light-gray w3-round">{item.information}</div>
                        : null
                    }
                </div>
                <div className="w3-padding-small"><strong className="w3-text-gray">{t("created")}:</strong> {moment(item.createdAt).fromNow()}</div>
                <div className="w3-padding-small"><strong className="w3-text-gray">{t("serviceEndDate")}:</strong>
                {item.serviceEndDate ? moment(item.serviceEndDate,'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY'):'NA'}</div>
            </div>
        );

        return (
            <div className="w3-container w3-padding">
                <h4 className="w3-center w3-margin-bottom" style={{ marginTop: '0px' }}>{t("serviceProviderDetails")}</h4>
                <div className="w3-row w3-margin-bottom" style={{ paddingBottom: '20px' }}>
                    {leftCont}
                    <div className="w3-col l6">
                        <div className="w3-padding-16"><strong className="w3-text-gray">{t("Address")}:</strong>
                            <div className="w3-border w3-padding-small w3-padding-16 w3-light-gray w3-round">{item.address}</div>
                        </div>
                        <div id="provider-google-map-details" style={{ minHeight: "250px" }}></div>
                    </div>
                </div>
            </div>
        )
    }
}
export const ServiceProviderDetail = (withTranslation()(ServiceProviderDetailComponent))

class ServiceProviderList extends Component {
    constructor(arg) {
        super(arg);
        this.state = {
            data: null,
            modal: null
        }
        this.filter = {}
    }

    fetchData(props) {
        let { page = 1, status = 'list' } = props.match.params;
        let { search } = props;
        this.setState({ data: null });

        if (status == 'search') {
            search = page;
            page = 1;
        }

        let obj = {
            status: status,
            page: page,
            q: search,
        }
        obj = Object.assign(obj, this.filter);
        const str = qs.stringify(obj);
        axios.get(`/api/v1/service-provider/list?${str}`).then(resp => {
            this.setState({
                data: resp.data.data
            });
        });
    }

    componentDidMount() {
        this.fetchData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params != this.props.match.params
            || this.props.search != nextProps.search) {
            this.fetchData(nextProps);
        }
    }

    handleFilterData(filterData) {
        this.filter = filterData;
        this.fetchData(this.props);
    }

    hideModal() {
        this.setState({ modal: null })
    }

    showDetailModal(item) {
        this.setState({
            modal: (
                <Reveal onClose={this.hideModal.bind(this)}>
                    <ServiceProviderDetail hideModal={this.hideModal.bind(this)}
                        item={item} />
                </Reveal>
            )
        });
    }

    render() {
        const { data } = this.state;
        const { page = 1 } = this.props;

        let pagination = null;
        let content = null;
        let totalCount = '';
        if (!data) {
            content = <Spinner />
        } else if (data.list.length == 0) {
            content = <div className="w3-col l12 w3-padding-64 w3-large w3-center">The List is empty</div>
        } else {
            pagination = <Paginator data={data} page={page} />
            totalCount = `Total: ${data.total}`;
            content = data.list.map((item, idx) =>
                <ServiceProvider key={idx} item={item}
                    showDetailModal={this.showDetailModal.bind(this)} />
            );
        }

        return (
            <div style={{ minHeight: "100vh" }}>
                <Header />
                <div className="w3-row-padding w3-padding" style={{minHeight:'90vh'}}>
                    <div className="w3-col m4 l3 ">
                        <ProviderFilter data={data}
                            handleFilterData={this.handleFilterData.bind(this)} />
                    </div>
                    <div className="w3-col m8 l9 ">
                        <div className="w3-row" style={{ display: 'flex', flexFlow: 'wrap' }}>
                            {content}
                        </div>
                    </div>
                    <div className="w3-col w3-center">
                        {pagination}
                    </div>
                    {this.state.modal}
                </div>
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        search: state.searchText
    }
}

export default connect(mapStateToProps)(withRouter (withTranslation()(ServiceProviderList)))