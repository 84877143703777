import  React,{ Component } from 'react';

class Attachments extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    render(){

        let {data} = this.props
        return(
            <div className='w3-container'>
                {data.map(obj => {
                    return(<img className='w3-image w3-padding' style={{maxWidth: '100%' , height:'100%'}} src={obj.url}/>)
                    
                    
                })}
            </div>
            
        )
    }
}

export default Attachments