import React, { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import configureStore from './redux/configureStore';
import { initLang } from './i18n';
import axios from "axios";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setData } from './redux/actions';

import Home from './components/Home'
import Dashboard from './components/Dashboard'
import ProviderList from './components/Provider/Lister'
import HeatMap from './components/HeatMap'
import AddRequestModal from './components/Request/AddModal'

import LandingPage from './components/Homepage'
import PrivacyPolicy from './components/PrivacyPolicy'
import TermsConditions from './components/TermsConditions'
import Certificate from './components/Widgets/Certificate' 


require('./scss/style.scss');

const App = ({ routes }) => {
    const dispatch = useDispatch()
    const localLanguage = localStorage.getItem('appLanguage')
    const storeLanguage = useSelector((state) => state.appLanguage);
    const language = localLanguage ?? storeLanguage
    if (localLanguage) {
        dispatch(setData('appLanguage', localLanguage))
    }

    useEffect(() => {
        axios.get(`/api/v1/translation/${language}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            console.log('new langugae',response.data);
            initLang(language,response.data);
        });
    }, [language])

    return (
        <Suspense fallback="loading">
            <BrowserRouter>
                <Switch>
                    {
                        routes.map((r, idx) =>
                            <Route exact={r.exact} key={`route_${idx}`}
                                path={r.path} component={r.component} />
                        )
                    }
                </Switch>
            </BrowserRouter>
        </Suspense>
    )
}


const loadApp = async function () {

    let state = {};
    try {
        const resp = await axios.get('/api/v1/config');
        state = resp?.data?.data;
    }
    catch (err) {
        console.error("Error occured while getting config");
    }
    const store = configureStore(state)
    window.gAppStore = store;

    const routes = [
        {
            path: '/new',
            exact: true,
            component: AddRequestModal,
        },
        {
            path: '/heatmap/:status?',
            exact: true,
            component: HeatMap
        },
        {
            path: '/dashboard/:requestId?',
            component: Dashboard,
        },
        {
            path: '/requests/:status?/:page?',
            exact: true,
            component: Dashboard,
        },
        {
            path: '/providers/:status?/:page?',
            exact: true,
            component: ProviderList,
        },
        {
            path: '/home/:label',
            exact: true,
            component: Home,
        },
        {
            path: '/hero/:id',
            exact: true,
            component: Certificate,
        },
        {
            path: '/app',
            component: Home,
        },
        {
            path: '/site',
            component: LandingPage,
        },
        {
            path: '/privacy',
            exact: true,
            component: PrivacyPolicy,
        },
        {
            path: '/terms',
            exact: true,
            component: TermsConditions,
        },
    ];
    if (state.missionId != 'demo') {
        routes.push({
            path: '/',
            exact: true,
            component: Home,
        });
    } else {
        routes.push({
            path: '/',
            exact: true,
            component: LandingPage,
        });
    }
    ReactDOM.render(
        <Provider store={store}><App routes={routes} /></Provider>,
        document.getElementById('app')
    );

};

loadApp();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();