import React from "react"
import { TANDC } from '../locales/data'
import { Footer } from "./Common/Footer"
import Header from "./Common/Header"
// import Header from "../components/Header"

const TermsConditions = () => (
  <div>
    <Header />
    <div className="terms-main w3-content">
      <h4 className='w3-center w3-padding-16'>Terms Of Service</h4>
      {TANDC.map(item => <div className='w3-padding'>
        <p className='fab-agreement-title'>{item.title}</p>
        <p className='fab-agreement-desc '>{item.content}</p>
      </div>)}
    </div>
    <Footer/>
  </div>
)
export default TermsConditions