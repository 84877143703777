import  React,{ Component } from 'react'; 
import { FormTextField,FormTextarea,Spinner,SelectField, GooglePlacesAutoComplete} from './../Common/Helper.js';
import { withTranslation } from 'react-i18next';  
import Reveal from '../Common/Reveal.js';
import GoogleMapWidget from './../Widgets/GoogleMap';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { showMessage, hideMessage,getLatLng } from './../../redux/actions.js';   
import Attachments from './Attachments.js'; 
import {ErrorHelperText} from '../Common/Helper'
  

const RowItem =({name,value}) => {

    return <tr  style={{padding:"2px 0px"}} >
        <td style={{color:"#888"}}>{name}:</td>
        <td style={{wordWrap:'break-word',marginLeft:"2px"}}>{value}</td>
    </tr>
}

class DetailsModal extends Component {
    constructor(args){
        super(args);
        this.state =   {
            mode:'status',
            updateForm:{},
            updateErrors:{},
            workLog:null,
            update:null,
            place:null,
            form: {},
            errors: {}, 
            successMessage: '',
            modal: null,
        }
        this.map = null;
        this.marker = null;
        this.handlePlaceChange = this.handlePlaceChange.bind(this);
    }

    locationSelect(lat,lon){ 
        this.state.updateForm.location_lat = lat;
        this.state.updateForm.location_lon = lon;
    }

    handlePlaceChange(place){ 
        if (!place.geometry) {
          return;
        }
        this.state.updateForm.address_components  = place.address_components;
        this.setState({
            place:place
        });
    } 
    componentDidMount(){
        const {item,id} = this.props;
        this.state.form.id = id;
        axios.post(`/api/v1/rescue/lock`,{id:this.props.id}).then(resp=>{
            
            this.state.form.severity = resp.data.data.request.operatorSeverity;
            
            this.setState({
                update:resp.data.data.request,
                workLog:resp.data.data.log
                
            });
            
            
        })
    }
    componentWillUnmount(){
        axios.post('/api/v1/rescue/release-lock',{id:this.props.id}).then(res => {
        });
    }
    changeFormValue (name,value) {
        this.state.form[name] = value;
    }

    saveDetails(){
        const {item} = this.props;
        var formData = this.state.updateForm
        
        //checking for required fields in rescue
        if(formData.type === 'rescue'){
            let errors = {}
            if (!formData.title) {
                errors['title'] = "Title is required"
            }
            if (!formData.personName) {
                errors['personName'] = "Name is required"
            }
            if (!formData.type) {
                errors['type'] = "Type is required"
            }
            if (!formData.address) {
                errors['address'] = "This field is required"
            }
            if (!formData.other_address) {
                errors['other_address'] = "This field is required"
            }
            if (!formData.phoneNumber) {
                errors['phoneNumber'] = "Phone Number is required"
            }
            else if(formData.phoneNumber && formData.phoneNumber.length != 10 && formData.phoneNumber != 'Masked'){
                errors['phoneNumber'] = "Invalid Phone Number"
            }
            if (!formData.peopleCount) {
                errors['peopleCount'] = "This field is required"
            }
            const strng = formData.address
            const trimString = strng.trim()
            
            if (!trimString) {
                errors['address'] = "Address is required"
            }
            
    
            if (Object.keys(errors).length != 0) {
                this.setState({
                    errors: errors
                });
                return false;
            }
        }else{ //checking required fields for telecom & flood
            let errors = {}
            if (!formData.title) {
                errors['title'] = "Title is required"
            }
            if (Object.keys(errors).length != 0) {
                this.setState({
                    errors: errors
                });
                return false;
            }
        }

        this.state.updateForm['peopleCount'] = this.state.updateForm.peopleCount !== '' ? this.state.updateForm.peopleCount : 0;
        axios.post('/api/v1/rescue/edit',this.state.updateForm).then(resp=>{
            this.setState({
                update:resp.data.data,
                mode:'status',
                errors:{}
            });
        });
    }

    changeUpdateValue(name,value){
        //this.state.updateForm[name]= value;

        this.setState({
            updateForm:{
                ...this.state.updateForm,
                [name]: value

            }
        });
        
    }

    handleUpdate(e){

        var formData = this.state.form
        let errors = {}

        if (formData.severity === '-') {
            errors['severity'] = "This field is required"     
        }
        else if(formData.severity == -1){
            errors['severity'] = "This field is required" 
        }
        if (!formData.comments) {
            errors['comments'] = "This field is required"     
        }
        if (Object.keys(errors).length != 0) {
            this.setState({
                errors: errors
            });
            return false;
        }
        
        axios.post('/api/v1/rescue/update',this.state.form).then(resp=>{
            
            if(!resp.data.meta.success){
                this.props.showMessage('fail',resp.data.meta.message);  
                if (resp.data.meta.message.indexOf('login') != -1) {
                    setTimeout(()=>{
                        document.location.href = "/";
                    },5000)
                }
                return;
            }
            this.props.showMessage('success','Changes updated');
            this.props.hideModal('reload');
        });
    }

    changeMode(mode){
        const {update} = this.state;

        this.state.updateForm = {
            id:update.id,
            type:update.type,
            personName:update.personName,
            phoneNumber:update.phoneNumber,
            address:[update.location,update.address].join('') ,
            information:update.information,
            peopleCount:update.peopleCount,
            title:update.json.input.title,
            waterHeight:update.json.input.waterHeight,
            other_address:update.json.input.other_address,
            
            // detailrescue:update.json.detailrescue,
            // detailwater:update.json.detailwater,
            // detailtoilet:update.json.detailtoilet,
            // detailfood:update.json.detailfood,
            // detailmed:update.json.detailmed,
            // detailkit_util:update.json.detailkit_util,
            // detailcloth:update.json.detailcloth,
            // detail:update.json.detail,
        } 
        
        this.state.updateErrors={};
        this.setState({
            mode:mode,
            errors: {}
        });
        
    }

    changeAssign = () => {
        try{
            axios.post('/api/v1/rescue/assign',{"id" : this.state.update.id})
            .then(resp=> {
                const request = resp.data.data;
                this.setState({
                    update: request
                })
            });
        }catch(err){
            console.error(err);
        }
    
       
    }

    hideModal = () => {
        this.setState({
            modal: null
        })
    }

    openAttachment = () => {
        this.setState({
            modal: (<Reveal onClose={this.hideModal}>
                    <Attachments data= {this.state.update.json.attachments} />
                </Reveal>)
        })
    }

     renderEdit(help_type){
        const { t } = this.props;
        const {update} = this.state; 
        const latLng = getLatLng(update);

       //Displaying dynamically the edit page depends on the type of Issue
        const getFields=()=>{
            return (this.props.fields).map((tItem) => {
                const item1 = tItem?.value?.value?.replace(/\n/gm,'')
                const item2 = item1?.split(',')
                return item2?.map(item => {
                    const splitValue = item?.split(':')

                    return {
                        key:splitValue[0],
                        type:splitValue[1],
                        value:splitValue[2],
                        topKey:tItem.key
                    }
                })
            }).flat()
        }
        
        const renderFormFields =() => {
            const fieldsArray = getFields()
            const {updateForm} = this.state
            if(!(fieldsArray && fieldsArray.length > 0)){
                return
            }
            const renderFieldArr = fieldsArray.filter(item=>item?.topKey == updateForm.type)
            const renderFields = []
            for(const fieldItem of renderFieldArr){
                let field = null
                if(fieldItem.type == 'Str'){
                    field = (
                        <div className="">
                            <FormTextField inputClass="w3-input w3-small w3-border w3-round-large" 
                            label={fieldItem.value}
                            name={fieldItem.key} 
                            isMandatory="true"
                            defaultVal={update[fieldItem.key]}
                            value = {this.state.updateForm[fieldItem.key]}
                            valueChange={this.changeUpdateValue.bind(this)}
                            errors = {this.state.errors[fieldItem.key]} 
                        />
                        </div>
                    )
                }else if(fieldItem.type == 'Num'){
                    field = (
                        <div className=''>
                            <FormTextField 
                                isMandatory="true"
                                label={t(`${fieldItem.value}`)}
                                placeholder={fieldItem.value}
                                name={fieldItem.key}
                                inputClass="w3-input w3-border w3-round-large"
                                value = {this.state.updateForm[fieldItem.key]}
                                valueChange={this.changeUpdateValue.bind(this)}
                                errors = {this.state.errors[fieldItem.key]}/> 
                        </div>
                    )
                }else if(fieldItem.type == 'NotMandate'){
                    field = (
                        <div className="">
                            <FormTextField inputClass="w3-input w3-small w3-border w3-round-large" 
                                label={fieldItem.value}
                                name={fieldItem.key}
                                defaultVal={update[fieldItem.key]}
                                value = {this.state.updateForm[fieldItem.key]}
                                valueChange={this.changeUpdateValue.bind(this)}
                            /> 
                        </div>
                    )
                }
                if(field){
                    renderFields.push(field)
                }
            }
            return renderFields
        }


        return <div className="w3-panel w3-padding w3-white">
                <div className="w3-row-padding w3-margin-bottom"> 
                    <div  className="w3-col l12 "> 
                        <div className='tickets-caseid'>
                        <div style={{backgroundColor:'#fcc512'}} className='w3-margin-bottom  w3-border w3-round w3-padding-small'>Case ID {update.id}{update.remoteId}</div>
                        </div>
                    </div>
                    <div className="w3-col l12 w3-margin-bottom">
                    <GooglePlacesAutoComplete  
                        onPlaceChange={place => this.handlePlaceChange(place)}
                        placeholder = "Search Location to drop pin" /> 
                    <GoogleMapWidget mapStyle={{height: '300px'}} 
                        mapId="google-edit-map" 
                        place={this.state.place} 
                        location={{lat:latLng.lat,lng:latLng.lng}}
                        locationSelect={this.locationSelect.bind(this)}/>
                        
                    </div>
                <div className="w3-col l6 s12">
                     <SelectField inputClass="w3-input w3-small w3-border" 
                        label="Issue Type"
                        name="type" 
                        isMandatory="true"
                        defaultVal={update.type}
                        value = {this.state.updateForm.type}
                        valueChange={this.changeUpdateValue.bind(this)}
                        errors = {this.state.errors.type} 
                    >
                    
                    {this.props.issueTypes.map(item =><option 
                            key={item.value} 
                            value={item.value}>{t(item.value)}</option>)}
                            
                    </SelectField>
                    
                    {help_type && renderFormFields()} 
                    
                    <FormTextarea inputClass="w3-input w3-small w3-border w3-round-large" 
                        label="Extra Information" 
                        name="information"  
                        value = {this.state.updateForm.information}
                        valueChange={this.changeUpdateValue.bind(this)}
                        errors = {this.state.updateErrors.information} 
                    />
                </div>
                <div className="w3-col l6 s12">
                    <div className="w3-margin-top">
                    <button className="w3-button w3-round w3-small w3-black w3-right" 

                        onClick={this.changeMode.bind(this,'status')}>{t("cancel")}</button>
                    <button onClick={this.saveDetails.bind(this)} 
                        className="w3-button w3-margin-right w3-round w3-small w3-green w3-right">{t("saveInfo")}</button>
                    </div>
                </div>
                </div> 
        </div>
    }

    
    render(){
        let {item ,t,userToRole,authUser,statusToRole}  = this.props;
                 
        const {workLog,update} = this.state;

        //Displaying the status dropdown in the update, based on the roles provided
        var names = userToRole.map(function(i) {
            return i.id
        })

       var removeDuplicateNames = [...new Set(names)]

       let foundEmail = removeDuplicateNames.find(element => element === authUser.email)
       let statusListOwner = []
        if(foundEmail){
            let mailList = userToRole.filter(obj => obj.id === foundEmail)
            let ownerDetails = mailList.map(owner => owner.ownership )
            statusListOwner = statusToRole.filter(obj => {
                return ownerDetails.includes(obj.ownership);
                });
       }else{
         statusListOwner = statusToRole.filter(obj => obj.ownership === 'default')
       }
       
        //disabling Attachment button if there is no attachments
        let disableAttachment = false
        // if(update?.json?.attachments.length === 0){
        //     disableAttachment = true
        // }

        if(update){
            item = update;
        } 

        let flow = null,link = null;
        let mapStyle= { display:'none' };
        let content = <Spinner />

        let attachments = (
            <button disabled= {disableAttachment} onClick={this.openAttachment}>View Attachments</button>
        )
        
        if (workLog){
            content = workLog.map(item => {
                let statusRollIn = null
                if(item.statusIn  === 'NEW'){
                    statusRollIn = "tickets-status-new"
                }else if(item.statusIn === 'TODO'){
                    statusRollIn = "tickets-status-todo"
                }else if(item.statusIn === 'DOING'){
                    statusRollIn = "tickets-status-doing"
                }else if(item.statusIn === 'COMPLETED'){
                    statusRollIn = "tickets-status-completed"
                }else{
                    statusRollIn = "w3-tag w3-purple w3-tiny w3-padding-small w3-round"
                }

                let statusRollOut = null
                if(item.statusOut === 'NEW'){
                    statusRollOut = "tickets-status-new"
                }else if(item.statusOut === 'TODO'){
                    statusRollOut = "tickets-status-todo"
                }else if(item.statusOut === 'DOING'){
                    statusRollOut = "tickets-status-doing"
                }else if(item.statusOut === 'COMPLETED'){
                    statusRollOut = "tickets-status-completed"
                }else{
                    statusRollOut = "w3-tag w3-purple w3-tiny w3-padding-small w3-round"
                }
                
                return <div  className="w3-panel " key={`item_${item.id}`}>
                    <div style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}> 
                        <a href={item.providerId}>
                            <img className="w3-circle " src={item?.actor?.profileLink} style={{display:"inline-block",marginRight:"10px",width:"32px",height:"32px"}}/> 
                        </a>
                        {item.comments.indexOf('DATA_EDIT') == 0 ? `${t("fieldsUpdates")}`: item.comments}</div>
                    <div>
                        <div className="w3-row " style={{marginTop:"4px"}}>
                            <div className="w3-left ">
                                {(item?.json?.dataEdits || []).map(obj => (<ul>
                                                                        <li style={{color:'grey'}}><span>{t("updatedField")}:</span><span style={{color:'black'}}>{obj.field}</span></li> 
                                                                        <li style={{color:'grey'}}><span>{t("previousValue")}:</span>{obj.currentvalue == null ? <span style={{color:'black'}}>---</span> : <span style={{color:'black'}}>{obj.currentvalue}</span>}</li>
                                                                        <li style={{color:'grey'}}><span>{t("updatedValue")}:</span><span style={{color:'black'}}>{obj.newValue}</span></li>
                                                                    </ul>))}
                                <span className={statusRollIn}>{item.statusIn}</span> 
                                <span style={{padding:"0px 4px"}}>to</span>
                                <span className={statusRollOut}>{item.statusOut}</span>
                            </div>
                            <div className="w3-right w3-small w3-text-grey">
                                Updated by {item?.actor?.name} {moment(item.createdAt).fromNow()}</div>
                        </div>
                    </div> 
                    <hr/>
                </div>
            })
        }
        if (item){
            flow = this.props.statusList.find(i=> 
                i.key.toLowerCase() == item.status.toLowerCase()
            );
            if (item.latLng){
                mapStyle = {minHeight:"200px"}
            }
            link = <a target="_blank" 
            href={`https://www.keralarescue.in/request_details/${item.remoteId}/`} 
            >{item.source}</a>;
        } else{
            return <Spinner />
        }
        let memberCount = null
        if(item.peopleCount){
            memberCount = <div className='tickets-people-count-number'>{item.peopleCount}</div>
        }else{
            memberCount = <div className='tickets-people-count-number'>0</div>
        }

        let updateForm = null; 
        if (update && (!update.operator || update.operator.id == this.props.authUser.id)) {
        
            updateForm = <form className="w3-margin-top"> 
                <div  >  
                    <FormTextarea 
                        label={t("comments")}
                        placeholder={t("commentsDesc")}
                        name="comments"
                        inputClass="w3-input w3-border w3-round-large"
                        isMandatory="true"
                        value = {this.state.form.comments}
                        valueChange={this.changeFormValue.bind(this)}
                        errors = {this.state.errors.comments} 
                    />
                </div>
                <div >
                    <SelectField 
                    label={t("howSeverIssue")}
                    id="severity_select"
                    name="severity"
                    selectClass="w3-select w3-border w3-round-large"
                    isMandatory="true" 
                    value = {this.state.form.severity}
                    valueChange={this.changeFormValue.bind(this)}
                    errors = {this.state.errors.severity}
                    >
                <option value="-">---</option>
                <option value="0">{t("notSevere")}</option>
                <option value="1">{t("moderate")}</option>
                <option value="2">{t("needsHelp")}</option>
                <option value="3">{t("urgent")}</option>
                <option value="4">{t("veryUrgent")}</option>
                <option value="5">{t("lifeThreatening")}</option>
                </SelectField>
            </div>
                <div  >
                    <SelectField 
                        label={t("changeStatus")}
                        name="status"
                        selectClass="w3-select w3-border w3-round-large"
                        isMandatory="true"
                        value = {this.state.form.status}
                        valueChange={this.changeFormValue.bind(this)}
                        errors = {this.state.errors.status}
                        >
                        <option value="--">---</option>
                    {this.props.statusList.map(item => <option key={item.status} value={item.status}>{item.title}</option>)}
                    </SelectField>
                </div>
                <div className="w3-col s12 w3-margin-top">
                {update.assigneeId !== this.props.authUser.id && <button style={{marginLeft:'0px', backgroundColor:'#FEF3CD', borderColor:'#CB9B01', border:'2px'}} className='w3-button w3-padding-small w3-round w3-border' type='button' onClick={this.changeAssign}>{t("assignToMe")}</button>}
                
                    <button type="button"
                    onClick={this.handleUpdate.bind(this)} 
                    style={{marginRight:'0px'}}
                    className="w3-button w3-round w3-green w3-light-grey w3-right w3-padding-small w3-border">{t("update")}</button> 
                </div> 
            </form>
        } else if (update && update.operator) { 
            updateForm = <div>Working :{update.operator.name}</div>
        }

        if (this.state.mode == 'edit' && update) {
            return this.renderEdit(item.type);
        }

        //Displaying details in the 'Help Now' modal depends on type of Issue
        let leftContIssueTyp 
        if(item.type === 'rescue'){
            leftContIssueTyp =(<>
                <RowItem name={t("name")} value={item.personName} />
                <RowItem name={t("Number of people")} value={memberCount}/>
                <RowItem name={t("phone")} value={<a className="w3-buttom w3-tag w3-redice w3-round w3-pink" style={{textDecoration: 'none'}} href={`tel:${item.phoneNumber}`} > {item.phoneNumber}</a>} />
                {/* <RowItem name="Location (lat,lon)" value={`${item.json.input.location_lat} , ${item.json.input.location_lon}`}/> */}
                <RowItem name={t("District")} value={item.district} />
                <RowItem name={t("Address")} value={item.address} />
                {/* <RowItem name="Alternate Address" value={item.json.input.other_address}/> */}
                <RowItem name={t("serverity")} value={item.operatorSeverity == -1 ? 'Not Updated':item.operatorSeverity}  />
                <RowItem name={t("MoreInfo")} value={item.information} />
                <RowItem name={t("status")} value={<a className='tickets-status'>{item.status} </a>}/>
                <RowItem name={t("created")} value={moment(item.createdAt).fromNow()} />
                <RowItem name={t("volunteerStatus")} value={<a className='tickets-status'>{item.operatorStatus} </a>} />
                <RowItem name={t("volunteerActed")} value={item.operatorUpdatedAt? moment(item.operatorUpdatedAt).fromNow(): '-'} />
            </>
            )
        }else if(item.type === 'telecom'){
            leftContIssueTyp =(<>
                <RowItem name="Title" value={item.json.input.title}/>
                <RowItem name="Location (lat,lon)" value={`${item.json.input.location_lat} , ${item.json.input.location_lon}`}/>
                <RowItem name={t("District")} value={item.district} />
                <RowItem name={t("serverity")} value={item.operatorSeverity == -1 ? 'Not Updated':item.operatorSeverity}  />
                <RowItem name={t("MoreInfo")} value={item.information} />
                <RowItem name="Status" value={<a className='tickets-status'>{item.status} </a>}/>
                <RowItem name={t("created")} value={moment(item.createdAt).fromNow()} />
                <RowItem name={t("volunteerStatus")} value={<a className='tickets-status'>{item.operatorStatus} </a>} />
                <RowItem name={t("volunteerActed")} value={item.operatorUpdatedAt? moment(item.operatorUpdatedAt).fromNow(): '-'} />
            </>
            )
        }else {
            leftContIssueTyp =(<>
                {/* <RowItem name="Title" value={item.json.input.title}/> */}
                {/* <RowItem name="Location (lat,lon)" value={`${item.json.input.location_lat} , ${item.json.input.location_lon}`}/> */}
                <RowItem name={t("District")} value={item.district} />
                <RowItem name={t("serverity")} value={item.operatorSeverity == -1 ? 'Not Updated':item.operatorSeverity}  />
                <RowItem name={t("MoreInfo")} value={item.information} />
                <RowItem name={t("Number of people")} value={memberCount}/>
                {/* <RowItem name="Water Height" value={item.json.input.waterHeight}/> */}
                <RowItem name={t("status")} value={<a className='tickets-status'>{item.status} </a>}/>
                <RowItem name={t("created")} value={moment(item.createdAt).fromNow()} />
                <RowItem name={t("volunteerStatus")} value={<a className='tickets-status'>{item.operatorStatus} </a>} />
                <RowItem name={t("volunteerActed")} value={item.operatorUpdatedAt? moment(item.operatorUpdatedAt).fromNow(): '-'} />
                {/* <RowItem name="Attachments" value={attachments}/> */}
            </>
            )
        }

        let leftCont = <div className="w3-col l6 s12 ">
            <h6 className='w3-padding'>{t("issueDetails")}</h6>
            <div className='w3-padding tickets '> 
                <div className='tickets-caseid'>
                    <div style={{backgroundColor:'white'}} className='  w3-border w3-round w3-padding-small'>{t("caseId")} : {[item.id,item.remoteId].join('-')}</div>
                    <a onClick={this.changeMode.bind(this,'edit')} className="w3-link-button w3-button w3-padding-small" type="button" style={{textDecoration: 'underline', textDecorationColor: '#fab648'}} >{t("editInfo")}</a>
                </div>
            </div>
            <h5 className='w3-padding'>{t(item.type.toUpperCase())}</h5>
           <table className='w3-table'>
            {leftContIssueTyp}
            </table>
     
        </div> ;

        const latlng = getLatLng(item);

       return <div className="w3-container w3-padding ">   
                <div className="w3-row-padding w3-margin-bottom">
                    {leftCont}
                    <div className="w3-col l6 s12">  
                        <GoogleMapWidget 
                            mapStyle={{minHeight:"200px"}} 
                            mapId="google-map-detail" 
                            location={latlng} />
                       {updateForm}
                       {this.state.modal}
                    </div>
                </div>    
                <hr/>
                {content}
            </div>
        
    }
}

export default connect((state)=>{
    return {
        requestTypeList:state.requestTypeList,
        authUser:state.authUser,
        severityList:state.severityList,
        statusList:state.statusList,
        issueTypes:state.issueTypes,
        fields: state.fields,
        userToRole: state.userToRole,
        statusToRole: state.statusToRole
        
    }
}, { 
    showMessage, 
    hideMessage
})(withTranslation()(DetailsModal));