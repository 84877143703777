import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import FilterSelect from '../Common/FilterSelect';
import axios from 'axios';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

class FilterComponent extends React.Component {
    constructor(args) {
        super(args);

        let end = moment().endOf('day').add(1, 'minute');


        let tm = new Date();
        const timeList = [];
        let endStr = 'Now';
        let endRange = moment();

        for (var idx = 0; idx < 12; idx++) {
            end = end.subtract(6, "Hours");

            if (end < tm) {
                timeList.push({
                    name: end.format('Do hh a') + " - " + endStr,
                    value: `${endRange.valueOf()}-${end.valueOf()}`
                });
                endRange = end.clone();
                endStr = endRange.format('Do hh a');


            }
        }

        timeList.push({
            name: 'older',
            value: `${moment().subtract(3650, 'days').valueOf()}-${end.valueOf()}`
        });

        this.state = {

            filterLabels: {
                sort: 'Sort',
                time: 'Time',
                severity: 'Severity All',
                district: 'District All',
                type: 'Request Type'
            },
            filterData: {},
            districtList: [],
            sortConfig: [
                {
                    type: 'sort',
                    value: 'DESC',
                    name: 'Newest First',
                },
                {
                    type: 'sort',
                    value: 'ASC',
                    name: 'Oldest First',
                }
            ],
            timeConfig: timeList,
            timerange: {
                from: null,
                to: null
            },
        }

    }

    componentDidMount() {
        axios.get('/api/v1/district-names')
            .then(resp => {
                resp = resp.data

                if (resp.meta.success) {
                    this.setState({
                        districtList: resp.data
                    })

                    return
                } else {


                }
            })
    }

    handleFilterData(type, e) {
        const { handleFilterData } = this.props;
        let { filterData } = this.state;
        filterData[type] = e.target.value;




        if (handleFilterData) {
            handleFilterData(filterData);
        }
    }

    handleTimeRange() {
        const { handleFilterData } = this.props;

        let { filterData, timerange } = this.state;

        if (timerange?.from && timerange?.to) {
            const startData = moment(timerange?.from).format('lll')
            const endData = moment(timerange?.to).format('lll')
            filterData.startAt = moment(startData).valueOf();
            filterData.endAt = moment(endData).valueOf();


            if (handleFilterData) {
                handleFilterData(filterData);
            }

        }

    }

    timerangeInput = (event) => {
        const { name, value } = event.target

        this.setState({
            timerange: {
                ...this.state.timerange,
                [name]: value
            }
        }, () => this.handleTimeRange())
    }

    render() {
        const { districtMap, districts, t } = this.props; 
        const { severityList, searchText, issueTypes, filterStats } = this.props;
 
         
        return (
            <div className=" w3-align-left" style={{ marginTop: '0px', paddingLeft: '0px' }} >
                <div className='w3-bar w3-white w3-margin-bottom' style={{ minHeight: "38px" }}>
                </div>
                <div className="w3-small w3-margin-top">
                    {searchText && `Searching "${searchText}"`}
                </div>
                <div className="w3-margin-top w3-hide-small w3-padding">
                    <div className='w3-row'>
                        <div className='w3-col w3-half w3-center' style={{ backgroundColor: '#BF6D0D', color: 'white', height: '50%' }}>
                            <h4 >{t("issues")}</h4>
                            <h4> {filterStats ? filterStats.total:'...'} </h4>
                        </div>
                        <div className='w3-col w3-half w3-center' style={{ backgroundColor: '#0647C6', color: 'white', height: '50%' }}>
                            <h4 >{t("totalDemand")}</h4>
                            <h4>{filterStats   ? filterStats.demand:'...'}</h4>
                        </div>

                    </div>
                    <FilterSelect
                        name={t("serviceType")}
                        filterOptions={issueTypes.map((item, idx) => ({
                            name: t(item.value),
                            value: item.value
                        }))}
                        handleFilterData={this.handleFilterData.bind(this, 'requestType')}
                    />
                    <FilterSelect
                        name={t("District")}
                        filterOptions={this.props.districts.map(name => {
                            return {
                                value: name,
                                name
                            }
                        })}
                        handleFilterData={this.handleFilterData.bind(this, 'district')}
                    />
                    <div>{t("timeRange")}</div>
                    <div className='time-range-block'>
                        <div className='from-to'>
                            <p style={{ marginBottom: '4px' }}>{t("startDate")}</p> <input className=' w3-border w3-round'
                                onChange={this.timerangeInput} name='from' value={this.state.timerange.from} type='datetime-local' />
                        </div>
                        <div className='from-to'>
                            <p style={{ marginBottom: '4px' }}>{t("endDate")}</p> <input className=' w3-border w3-round'
                                onChange={this.timerangeInput} name='to' value={this.state.timerange.to} type='datetime-local' />
                        </div>
                    </div>

                    <FilterSelect
                        name={t("severity")}
                        filterOptions={severityList}
                        handleFilterData={this.handleFilterData.bind(this, 'severity')}
                    />

                    <FilterSelect
                        name={t("sortOn")}
                        filterOptions={[
                            {
                                value: 'recent',
                                name: 'Recent First'
                            },
                            {
                                value: 'oldest',
                                name: 'Oldest First'
                            },
                            {
                                value: 'demand',
                                name: 'Demand'
                            },
                            {
                                value: 'demand_desc',
                                name: 'Demand Unknown First'
                            }
                        ]}
                        handleFilterData={this.handleFilterData.bind(this, 'sortOn')}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        districtMap: state.districtMap,
        searchText: state.searchText,
        severityList: state.severityList,
        filterStats: state.requestFilterStats,
        districts: state.districts,
        issueTypes: state.issueTypes
    }
}

export default withRouter(connect(mapStateToProps)(withTranslation()(FilterComponent)));