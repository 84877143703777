import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { setSearch } from "../../redux/actions";
import AddRequestModal from "./../Request/AddModal";
import MarkSafe from "./../Modals/MarkSafe";
import RequestDetailModal from "./../Request/Details";
import Auth from "./Auth/Auth";
import LogoImg from "../../../src/images/logo-white-sm.png";
import UserIcon from "../../../src/images/UserIcon.png";

import Reveal from "./../Common/Reveal";
import ProviderAddModal from "./../Provider/AddModal";
import { showModal, getSessionUser, setData } from "./../../redux/actions.js";
import { withTranslation } from "react-i18next";

const langOptions = [
  {
    name: "മ",
    value: "ml",
  },
  {
    name: "EN",
    value: "en",
  },
  {
    name: "অ",
    value: "asm",
  },
];

class Header extends Component {
  constructor(arg) {
    super(arg);
    const localLanguage = localStorage.getItem("appLanguage");
    this.state = {
      mobileMenu: "w3-hide",
      modalContent: null,
      modal: null,
      initialLanguage: localLanguage ?? "",
      //initialLanguage:localLanguage ? langOptions.find(fItem=>fItem.value == localLanguage) : langOptions[0]
    };
  }

  togggleMobile() {
    this.setState({
      mobileMenu: this.state.mobileMenu === "w3-hide" ? "w3-show" : "w3-hide",
    });
  }
  componentDidMount() {
    this.props.setSearch("");
    this.props.getSessionUser();
  }

  hideModal() {
    this.props.showModal("empty");
  }

  showModal(name, data) {
    let content = null;
    if (name === "help_center") {
      content = <ProviderAddModal hideModal={this.hideModal.bind(this)} />;
    } else if (name === "mark_safe") {
      content = <MarkSafe type="SELF" hideModal={this.hideModal.bind(this)} />;
    } else if (name === "mark_other_safe") {
      content = (
        <MarkSafe type="BEHALF" hideModal={this.hideModal.bind(this)} />
      );
    } else if (name === "willing_to_help") {
      // content = <MarkWillingToHelp  hideModal={this.hideModal.bind(this)}/>
    } else if (name === "add_request" || name === "request") {
      content = <AddRequestModal hideModal={this.hideModal.bind(this)} />;
      // }  else if (name ==='update_request') {
      //     content = <Reveal  onClose={this.hideModal.bind(this)} >
      //         <RequestDetailModal  hideModal={this.hideModal.bind(this)}
      //           id={data.id} />
      //     </Reveal>
    }
    this.setState({ modalContent: content });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.modalInfo !== this.props.modalInfo) {
      this.showModal(nextProps.modalInfo.name, nextProps.modalInfo.data);
    }
  }

  changeSearch(e) {
    this.props.setSearch(e.target.value);
  }

  openAuth = () => {
    this.setState({ modal: <Auth onClose={this.hideAuthModal} /> });
  };

  hideAuthModal = () => {
    this.setState({ modal: null });
  };

  onChangeSelect = (value) => {
    localStorage.setItem("appLanguage", value);
    this.props.setData("appLanguage", value);
    this.setState({
      initialLanguage: value,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <section className="top_section  w3-small">
        {this.state.modal}
        <nav className="w3-bar w3-padding-small w3-black header-top-bar">
          <div className="w3-left" style={{ margin: "2px 0px" }}>
            <a
              className="w3-bar-item header-logo "
              style={{ textDecoration: "none", padding: "0px" }}
              href="/app"
            >
              <img
                className="w3-margin-left"
                style={{ height: "34px", padding: "0px" }}
                 src={LogoImg}
              ></img><span>escue Hands </span>
            </a>
          </div>

          <div className="mobile-top-right">
            <div
              className="w3-bar-item w3-hide-large w3-hide-medium"
              style={{ padding: "0px", marginRight: "50px" }}
            >
              <div
                className="w3-dropdown-hover"
                style={{ backgroundColor: "transparent" }}
              >
                <button className="w3-button w3-black w3-round">
                  {this.state.initialLanguage
                    ? langOptions.find(
                        (fItem) => fItem.value == this.state.initialLanguage
                      ).name
                    : langOptions[0].name}
                </button>
                <div className="w3-dropdown-content w3-bar-block w3-border lang-option-container">
                  {langOptions.map((lItem) => (
                    <div
                      key={lItem.value}
                      className="w3-bar-item w3-button"
                      onClick={() => this.onChangeSelect(lItem.value)}
                    >
                      {lItem.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <button
                style={{ margin: "4px" }}
                className="w3-bar-item w3-small w3-cyan  w3-button  w3-hide-large w3-hide-medium w3-display-topright"
                onClick={this.togggleMobile.bind(this)}
              >
                &#9776;
              </button>
            </div>
          </div>

          <div
            className="w3-right top-row w3-hide-small"
            style={{ margin: "2px 0px" }}
          >
            <input
              style={{ width: "380px" }}
              onChange={this.changeSearch.bind(this)}
              className="w3-input w3-bar-item w3-round w3-small w3-rest w3-margin-right"
              placeholder={t("search")}
            />

            {!this.props.authUser && (
              <button
                onClick={this.openAuth}
                className="w3-bar-item w3-margin-right w3-round w3-button w3-orange "
              >
                {t("login")}
              </button>
            )}
            <NavLink
              className="w3-bar-item w3-button w3-link-button "
              to="/requests/new"
            >
              {t("issues")}
            </NavLink>
            <NavLink
              className="w3-bar-item w3-button w3-link-button "
              to="/heatmap/"
            >
              {t("heatMap")}
            </NavLink>
            <NavLink
              className="w3-bar-item w3-button w3-link-button"
              to="/providers/list/"
            >
              {t("helpProviders")}
            </NavLink>
            <div className=" w3-dropdown-hover w3-margin-left">
              <button className="w3-button w3-light-grey w3-round">
                {this.state.initialLanguage
                  ? langOptions.find(
                      (fItem) => fItem.value == this.state.initialLanguage
                    ).name
                  : langOptions[0].name}
              </button>
              <div className="w3-dropdown-content w3-bar-block w3-border lang-option-container">
                {langOptions.map((lItem) => (
                  <div
                    key={lItem.value}
                    className="w3-bar-item w3-button"
                    onClick={() => this.onChangeSelect(lItem.value)}
                  >
                    {lItem.name}
                  </div>
                ))}
              </div>
            </div>

            {this.props.authUser && (
              <div className="w3-dropdown-hover ">
                <NavLink className="w3-button w3-link-button" to="/dashboard">
                  <img style={{ height: "17px" }} src={UserIcon}></img>
                  <i
                    className="ion-arrow-down-b"
                    style={{ marginLeft: "4px" }}
                  />
                </NavLink>
                <div
                  className="w3-dropdown-content w3-bar-block w3-card"
                  style={{ right: "0px" }}
                >
                  <a
                    className="w3-bar-item w3-button"
                    title="Dashboard"
                    href="/dashboard"
                  >
                    {t("dashBoard")}
                  </a>
                  <a
                    className="w3-bar-item w3-button"
                    title="logout"
                    href="/logout"
                  >
                    {t("logOut")}
                  </a>
                </div>
              </div>
            )}
          </div>
        </nav>
        <div
          className={`w3-bar-block w3-border-top w3-hide-large w3-hide-medium ${this.state.mobileMenu}`}
        >
          {this.props.authUser ? (
            <a className="w3-bar-item w3-button w3-blue" href="/dashboard">
              {t("dashBoard")}
            </a>
          ) : (
            <button
              className="w3-bar-item  w3-blue w3-button "
              onClick={this.openAuth}
            >
              {t("login")}
            </button>
          )}
          <NavLink className="w3-bar-item w3-button w3-blue" to="/requests/">
          {t("issues")}
          </NavLink>
          <NavLink className="w3-bar-item w3-button w3-blue" to="/heatmap/">
          {t("heatMap")}
          </NavLink>
          <NavLink
            className="w3-bar-item w3-button w3-blue"
            to="/providers/list/"
          >
            {t("helpProviders")}
          </NavLink>
        </div>
        {this.props.children}
        {this.state.modalContent}
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    modalInfo: state.modalInfo,
    appLanguage: state.appLanguage,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setSearch,
    getSessionUser,
    showModal,
    setData,
  })(withTranslation()(Header))
);
