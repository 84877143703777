import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Reveal from './Common/Reveal';
import Createmission from './Createmission';
import Featureimg1 from '../images/feature1.png'
import Featureimg2 from '../images/feature2.png'
import Featureimg3 from '../images/feature3.png'
import Featureimg4 from '../images/feature4.png'
import Featureimg5 from '../images/feature5.png'
import Featureimg6 from '../images/feature6.png'
import Navigationimg from '../images/nav.png'

import Profileimg from '../images/profile.jpg'
import { Footer } from './Common/Footer';

const NumberBlock = ({ count, title }) => {
    return <div className="w3-col l2 m3 w3-center s6 w3-padding">
        <h3 className=''>{count} </h3>
        <div>
            <p className="header-status">{title}</p>
        </div>
    </div>
}

const FeaturesBlock = ({ title, image, children, moreLink }) => {
    return <div className="w3-col m4 s12  " style={{ padding: "4px ", }}>
        <div className='w3-padding w3-round w3-display-container' style={{ backgroundColor: 'white' }}>
            <img className='w3-padding' style={{ height: '60px' }} src={image}></img>
            <h4 className='w3-padding-small' style={{ minHeight: "72px" }}>{title}</h4>
            <div className='w3-content w3-padding w3-left-align' style={{ minHeight: '260px' }}>
                {children}
                {moreLink && <div className='w3-right-align w3-display-bottom-right' style={{ marginTop: '20px' }} >
                    <a href={moreLink} target="_blank">Read More</a>
                </div>}
            </div>
        </div>
    </div>
}

const LandingPage = ({ missionId,missionName }) => {
    const [modal, setModal] = useState(null);
    const homePage = useSelector(st => st?.homePage);

    const hideModal = () => {
        setModal(null);
    }

    const openCreateModal = () => {
        setModal(
            <Reveal onClose={hideModal}>
                <Createmission />
            </Reveal>
        )
    }

    return (
        <div>
            <section>
                <div style={{ backgroundImage: `url(${Profileimg})` }} className='showcase'>
                    <div style={{ marginTop: "-115px" }}>
                        <div className="w3-bar w3-yellow">
                            {modal}
                        </div>
                        <div className="navigation w3-bar w3-round w3-black ">
                            <h2>  <img className='w3-padding-small' src={Navigationimg}></img> Rescue Hands</h2>
                        </div>
                        <div className='w3-content'>
                            <div className='w3-container w3-center  w3-margin-top w3-round' style={{ background: "rgba(0,0,0,0.6)" }}>
                                <h1 className='w3-xxlarge w3-margin-bottom ' style={{ marginTop: '60px' }}>Rescue. Relief. Rebulid.</h1>

                                <div className='w3-large w3-section'>We are a crowdsourced disaster management and rescue platform.
                                    We work with Govt , Local Authorities and Volunteers to
                                    coordinate on the rescue and relief activities.
                                </div>
                                <div className='w3-large w3-section'>We allow people to raise requests in the hours of need,
                                    get support from
                                    individuals to fulfil those need by providing material, time and knowledge
                                    to solve the problem they are facing.
                                </div>
                                <div>

                                    <button onClick={openCreateModal} className='w3-button w3-amber w3-round w3-margin-64'>Create a new Rescue Mission</button>

                                </div>
                                <div className=' w3-round w3-section w3-padding' style={{
                                    border: "solid 4px rgba(0,0,0,0.3)",
                                    background: "linear-gradient(240deg, #000000ba, transparent)"
                                }}>
                                    <h4 className='w3-section w3-center'>On the Kerala Floods 2018 mission we had</h4>
                                    <div style={{ marginLeft: '10%' }} className="w3-container w3-row">
                                        <NumberBlock count="300+" title="Local Volunteers" />
                                        <NumberBlock count="45K+" title="Crowd Sourced Tickets" />
                                        <NumberBlock count="15K+" title="Tickets per Deployment" />
                                        <NumberBlock count="122 Days" title="Volunteer time contributed" />
                                        <NumberBlock count="100K+" title="Poeple Assisted" />
                                    </div>
                                </div>
                                <div s className="w3-row rh-missions ">

                                    <div className="w3-col  m6 s12 w3-left-align">
                                        <div className="w3-section w3-large w3-left-align">Active Mission</div>
                                        <ul className='w3-left-align'>
                                            <li>
                                                <a href='/app' className="missions">{missionName}</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="w3-col  m6 s12">
                                        <div className="w3-section w3-right-align w3-large">Past Missions</div>
                                        <ul className="w3-right-align">
                                            <li><a href='https://kerala2018.rescuehands.in' className="missions">Kerala Floods 2018</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='features w3-container w3-center w3-padding'>
                    <h3 className=" w3-center  w3-padding-32">Features We Offer</h3>
                    <div className='w3-content w3-padding-32'>
                        <div className="w3-row">
                            <FeaturesBlock title='Dynamic Request Management workflow'
                                moreLink="https://blog.rescuehands.in/adding-custom-fields-to-your-ticket-is-easy/" image={Featureimg1} >
                                <div >
                                    For each kind of disaster scenario the workflow needed for rescue and relief is different.
                                    We allow you to configure the workflow
                                    to your needs and allow role based access control for the state transition. We also keep a complete audit trail on all of the actions happend as part of the ticket
                                </div>
                            </FeaturesBlock>
                            <FeaturesBlock title='Easy deployment and Scaling' image={Featureimg2} moreLink="https://blog.rescuehands.in/how-you-can-easily-deploy-rescuehands/">
                                <div >
                                    In the moment of need getting a faster deployment and scaling up the system is very critical. We can help you on both of these. The applicaiton is
                                    containeraised and the same can be deployed and scaled on any of the IAAS platforms . GCP / AWS / AZURE/ Digital Ocean etc
                                </div>

                            </FeaturesBlock>
                            <FeaturesBlock title='Complete audit log for transparency' image={Featureimg3}
                                moreLink="https://blog.rescuehands.in/importance-of-audit-logs-on-crowdsourced-platform/"
                            >
                                <div >
                                    We maintain the complete life cycle of a request / ticket. in terms of who accessed the ticket. which fields were changed by an assignee
                                    We also provied role based access for state transitions

                                </div>
                            </FeaturesBlock>
                            <FeaturesBlock title='Clustering on logical grouping, location' image={Featureimg4} >
                                A robust search filtering and mechanism is provieded through an easy to navigate interface. which allows you to slice and dice data and efficiently plan  the execution of your operations.
                            </FeaturesBlock>
                            <FeaturesBlock title='Works based on open data formats' image={Featureimg5} >
                                <div>
                                    In a disaster scenario its important the application is able to talk to other applications.  RescueHands, works on top of robust REST API layer. which can be easily extended and can enable communcation / handshakre with different system and can be easily integrated.
                                </div>
                            </FeaturesBlock>
                            <FeaturesBlock title='Location based heat maps for targeting high impact areas' image={Featureimg6}
                                moreLink="https://blog.rescuehands.in/how-heat-maps-could-be-used-effectively-for-disaster-recovery/">
                                <div>
                                    We allow you to plan and execute the operation based on different heatmaps on location. ticket count, people affected, ticket severity.
                                    this allow you to channel resouces efficiently in the hour of need.
                                </div>
                            </FeaturesBlock>
                        </div>
                    </div>
                </div>
            </section>



            <section style={{ display: "none" }} className='section3 w3-container w3-center w3-padding w3-margin'>
                <h6 className="w3-padding w3-center">What’s happening around us!</h6>
                <div className='w3-container w3-yellow w3-card w3-round'>
                    <div className='w3-row-padding'>
                        <div className='w3-col m5 w3-padding'>
                            <img src={homePage?.aroundUsBannerImage} alt='banner-img' />
                        </div>
                        <div className='latest-news w3-col m7'>
                            <p>{homePage?.aroundUsBannerTag}</p>
                            <h3>{homePage?.aroundUsBannerHead}</h3>
                            <p>{homePage?.aroundUsBannerDescription}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section4 w3-padding w3-hide'>
                <h6 className="w3-padding w3-center">Blogs and News we got Featured</h6>
                <div className='blogs w3-card'>
                    <div className=' w3-conatainer w3-half w3-padding w3-border w3-card' style={{ padding: "10px" }}>
                        <div className='section4-bgdimg' style={{ backgroundImage: `url(${homePage?.blogsBannerImage1})` }}></div>
                        <div>{homePage?.blogsBannerTag1}</div>
                        <div>
                            <p>{homePage?.blogsBannerDescription1}</p>
                        </div>
                        <div><a href='#'>{homePage?.blogsBannerUrl1}</a></div>
                    </div>
                    <div className='w3-conatainer w3-half w3-padding w3-border w3-card' style={{ padding: "10px" }}>
                        <div className='section4-bgdimg' style={{ backgroundImage: `url(${homePage?.blogsBannerImage2})` }}></div>
                        <div>{homePage?.blogsBannerTag2}</div>
                        <div>
                            <p>{homePage?.blogsBannerDescription2}</p>
                        </div>
                        <div><a href='#'>{homePage?.blogsBannerUrl2}</a></div>
                    </div>
                </div>
            </section>
            <section className='section5'>
                <div className='w3-container w3-center'>
                </div>
            </section>
            <Footer />
        </div>

    )
}

const ms2p = (state) => {
    return {
        missionId: state.missionId,
        missionName: state.missionName
    }
}
export default connect(ms2p)(LandingPage)