import React, { Component } from "react";
import axios from "axios";
import certificateBG from "../../images/CertificateBG.png";
import DownloadBtn from "../../images/Download.png"; 
import Header from "../Common/Header";
import { Footer } from "../Common/Footer";
import * as moment from 'moment';
import QRCode from "qrcode.react";
 
class Certificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rescueMissions: 0,
      peopleHelped: '...',
      tmStart: '...',
      missionName: 'No Name',
      tmEnd: '...',
      name: '...',
      tmSpent: 0,
      profileImage: '/uploads/images/DefaultPhoto.jpg',
      certificateUrl: ''
    };
  }


  async componentDidMount() {

    // Extracting the user details
    await axios
      .get(`/api/v1/user-details?id=${this.props.match.params.id}`)
      .then(async (resp) => {
        if (resp.data) {
          this.setState(resp.data.data);
        }
      }).catch(ex => {
        console.error(ex);
      });
  }


  render() {
    const st = this.state;

    return (
      <div>
        <Header />
        <div>
          <div className="background " style={{
            background: `url(${certificateBG}) no-repeat`,
            padding: "100px 30px 30px 30px",
            backgroundPosition: "0px -150px",
          }}>
            <div
              className="w3-row w3-content"

            >
              <div className="w3-col m3">
                <img
                  src={st.profileImage}
                  style={{
                    borderRadius: "50%",
                    width: "200px",
                    position: "relative",
                    top: "-20px",
                    height: "200px",
                  }}
                ></img>
              </div>
              <div className="w3-col m9">
                <div className="description w3-mobile">
                  <h1
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontFamily: "Cabin",
                    }}
                  >
                    {this.state.userDetails?.name}
                  </h1>
                  {st.rescueMissions > 0 ?
                    <p className="rh-certificate-message">
                      We thank <b>{st.name} </b>for the selfless support provided in addressing the
                      helpless victims of <b>{st.missionName}</b>,
                      He/She started working at   <b>{moment(st.tmStart).format('YYYY MMM DD HH:mm')}</b> till &nbsp;
                      <b>{moment(st.tmEnd).format('YYYY MMM DD HH:mm')} </b> and
                      during the course worked on <b>{st?.rescueMissions}</b> help requests and
                      helped <b>{st?.peopleHelped}</b> people through missions updates and countless others during this time. You are a Super Hero.
                    </p>
                    : <p className="rh-certificate-message">
                      We thank <b>{st.name} </b>for joining the platform for helping victims of <b>{st.missionName}</b>,
                    </p>
                  }
                 
                </div>
              </div>
              {st.rescueMissions > 0 ? 
              <div className="w3-col m12">
                <div className="w3-right-align">
                  <a href={st.certificateUrl} download>
                    <img
                      src={DownloadBtn}
                      style={{ cursor: "pointer", ariaLabel: 'Download' }}
                    ></img>
                  </a></div>
                <div className="w3-section certificate">
                  <a href={this.state.certificateUrl} target="_blank">
                    <img src={st.certificateUrl} style={{ width: '100%' }} />
                  </a>
                </div>
                </div>: <div style={{height:'60vh'}}>..</div>}
                </div>
                <div className="w3-content">
                <div className="w3-right-align w3-section">
                    <a href="">View Raja</a> | 
                    <a href="">View Rama</a>
                  </div>

                <div className="w3-pale-blue w3-padding w3-round w3-section">
                  <h5>Disclaimer</h5>
                  This information above is generated based on data that was captured during the <b>{st.missionName}</b> on the keralafights later changed into rescuehands platform.
                  while the individuals might have made tremendous contributions out side the platform. This is just a way of we telling the volunteers a "Thank You".
                  Most of them doesnt even expect that as it was done as a service, thats why they deserve it all the more.
                </div>
                </div>
          </div>

        </div>

        <Footer />
      </div>
    );
  }
}

export default Certificate;
