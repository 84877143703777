/* global google */
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'query-string';
import { withTranslation } from 'react-i18next';
import { Spinner } from '../Common/Helper.js';
import Paginator from '../Common/Paginator.js';
import Reveal from './../Common/Reveal';

import logo from "../../../src/images/nav.png";
import { showMessage, showModal, setFilterStats } from './../../redux/actions.js';
import { Footer } from '../Common/Footer.js';
import SubHeader from '../Common/SubHeader'
import WorkingLogo from '../../images/WorkingLogo.png'

class RequestItemComp extends Component {
    render() {

        const { item, authUser, status, page, showDetailModal, t } = this.props;
        let inPageOption = null;
        if (item.parentId) {
            if (status == `duplicates:${item.parentId}` && item.status != 'RESOLVED') {
                inPageOption = <button onClick={e => this.props.markDuplicate(item)}
                    className="w3-display-topright w3-padding-small w3-small w3-round w3-button w3-amber">Mark Duplicate
                    of {item.parentId}-XXXX)</button>
            } else if (page != item.parentId) {
                inPageOption = <Link to={`/requests/duplicates:${item.parentId}`}
                    className="w3-display-topright w3-small w3-button w3-padding-small w3-round w3-amber" >
                    View Duplicates</Link>
            }
        }

        let actionItem = null;
        if (!authUser) {
            actionItem = <a
                className="   w3-padding-small w3-round w3-button w3-hover-white "
                style={{ backgroundColor: '#FCC512', fontSize: '14px', margin: "2px", textDecoration: 'none' }} href="/dashboard">
                <img src={logo} style={{ width: '16px', margin: '4px' }} />
                {t("loginToHelp")}
            </a>
        } else if (!item.operator || item.operator.id == authUser.id) {
            actionItem = <button type="button"
                className="w3-col l12 w3-padding-small w3-round w3-button" style={{ backgroundColor: '#FCC512', fontSize: '14px' }}
                onClick={e => showDetailModal(item)}>{t("helpNow")}</button>

        } else {
            actionItem = <div className="w3-col l12 w3-round w3-padding-small w3-button w3-border" style={{ backgroundColor: '#e3e3e3', color: '#959595', fontSize: '14px' }}>
                <img style={{ marginBottom: '3px' }} className='w3-margin-right' src={WorkingLogo}></img>{item.operator?.name}
            </div>
        }

        let wfitem = this.props.statusList.find(st => st.key.toLowerCase() == item.status.toLowerCase());
        let color = 'w3-border-purple';
        if (wfitem) {
            color = `w3-border-${wfitem.color}`
        }

        const idList = [item.id];
        let source = item?.source;
        if (item.remoteId) {
            idList.push(item.remoteId);
            source = (<a href={`https://www.keralarescue.in/request_details/${item.remoteId}/`} target="_blank">{item?.source}</a>);

        }


        let memberCount = null
        if (item.peopleCount) {
            memberCount = <div className='tickets-people-count-number'>{item.peopleCount} {t("people")}</div>
        }


        return (
            <div className=' lister w3-display-container' >
                <div key={`item_${item.id}`}
                    className={`${color}  lister-info  kf-request-item  w3-padding `}> 
                        
                        <div className='tickets-content-bottom w3-display-container'>
                            <div>
                                {item.json && item.json.tags && item.json.tags.map(name => {
                                    const colorMap = {
                                        'rescue': 'w3-red',
                                        'pregnant': 'w3-red',
                                        'food': 'w3-yellow',
                                        'medicine': 'w3-orange',
                                        'infant': 'w3-orange',
                                        'evac': 'w3-red',
                                    }
                                    let color = 'w3-blue';
                                    const keys = Object.keys(colorMap);
                                    for (const key of keys) {
                                        if (name.toLowerCase().indexOf(key) >= 0) {
                                            color = colorMap[key];
                                            break;
                                        }
                                    }
                                    return <div key={name}
                                        className={`w3-small w3-tag w3-light-grey  `}>
                                            <span className={`dot ${color}`}></span>
                                            {name}
                                        </div>
                                } )}
                            </div>
                            <div >
                                <div className='tickets-content-left'>{t("serviceType")} </div> <span className='tickets-content-right'> {t(item.type.toUpperCase())}</span><br />
                                <span className='tickets-content-left'>{t("source")} </span>  <span className='tickets-content-right'>{source}</span><br />
                                <span className='tickets-content-left'>{t("District")} </span> <span className='tickets-content-right'>{item.district}</span><br />
                                <span className='tickets-content-left'>{t("details")}  </span>  <span className='tickets-content-right'>{item.information}</span><br />
                            </div>
                            {(status == 'duplicates' || status == 'search') ?
                                <div>
                                    Operator Status : {item.operatorStatus} <br />
                                    Status : {item.status}
                                </div> : null
                            }
                            {inPageOption}
                            <div className='w3-right-align'>
                                <div style={{ fontSize: '10px', color: ' #707070' }}>{moment(item.createdAt).fromNow()}</div>
                            </div>
                        </div> 
                </div>
                <div className='lister-action w3-display-container w3-padding' style={{ minHeight: '100px' }}>
                    <div className='tickets'>
                        <div className='w3-center'>
                            <span style={{ backgroundColor: 'white' }} className='  w3-border w3-block w3-round w3-padding-small'>#{idList.join('-')}</span>
                        </div>
                        <div className='w3-margin-top'>
                            {memberCount && <div className='tickets-people-count '>
                                {memberCount}
                            </div>}
                        </div>
                        <div className='w3-display-hover w3-center ' >
                            {actionItem}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const RequestItem = (withTranslation()(RequestItemComp))

class RequestLister extends Component {
    constructor(arg) {
        super(arg);
        this.state = {
            data: null,
        };
    }
    showDetailModal(item) {

        this.props.showModal('update_request', {
            item: item,
            id: item.id
        })
    }

    hideModal(msg = '') {
        this.setState({ modal: null });
        if (msg == 'reload') {
            this.fetchData(this.props);
        }
    }

    fetchData(props) {

        let { page = 1, status = '', search = '' } = props;
        this.setState({ data: null });
        let obj = {
            status: status,
            page: page,
            q: search,
            per_page: 20,
        }
        obj = Object.assign(obj, props.filter);

        const str = qs.stringify(obj);
        axios.get(`/api/v1/rescue/list?${str}`).then(resp => { 
            this.setState({
                data: resp.data.data
            });

            this.props.setFilterStats({
                total: resp?.data?.data?.total,
                demand: resp?.data?.data?.demand
            })
        });
    }

    markDuplicate(item) {
        axios.post('/api/v1/rescue/update', {
            id: item.id,
            comments: 'Duplicate Confirmed',
            severity: 1,
            status: 'duplicate_resolved'
        }).then(resp => {
            if (!resp.data.meta.success) {
                this.props.showMessage('fail', resp.data.meta.message);
                return
            }
            this.props.showMessage('success', 'Changes updated');
            this.fetchData(this.props);
        });
    }

    componentDidMount() {
        this.fetchData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page != this.props.page) {
            this.fetchData(nextProps);
        } else if (nextProps.status != this.props.status) {
            this.fetchData(nextProps);
        } else if (nextProps.search != this.props.search) {
            this.fetchData(nextProps);
        } else if (nextProps.filter != this.props.filter) {
            this.fetchData(nextProps);
        }
    }

    handleFilterData(filterData) {
        this.filter = filterData;
        this.fetchData({ filter: this.filter });
    }


    render() {
        const { data } = this.state;
        const { page = 1, status = 'all' } = this.props;
        let pagination = null;
        let content = null;
        if (!data) {
            content = <div className='w3-center'>
                <Spinner />
            </div>
        } else if (data.list?.length == 0) {
            content = <div className="w3-padding-64 w3-large w3-center">The List is empty</div>
        } else if (data.list?.length > 0) {
            pagination = <Paginator data={data} base={`/requests/${status}`} page={page} />
            content = data.list.map(item => <RequestItem key={item.id}
                markDuplicate={this.markDuplicate.bind(this)}
                showDetailModal={this.showDetailModal.bind(this)}
                item={item}
                {...this.props}
            />);
        }

        return <div >
            <section className="top_section  w3-small">
                <SubHeader path='requests' handleFilterData={this.handleFilterData.bind(this)} />
            </section>
            <div className='w3-padding'>
                {content}</div>
            <div className="w3-center w3-margin-bottom">
                {pagination}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        authUser: state.authUser,
        statusList: state.statusList,
        search: state.searchText,
    }
}

export default connect(mapStateToProps, {
    showMessage,
    showModal,
    setFilterStats
})(RequestLister); 
