import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Newstatusimg from "../../images/NewData.png";
import Todostatusimg from "../../images/TodoData.png";
import Doingstatusimg from "../../images/DoingData.png";
import Completedstatusimg from "../../images/CompletedData.png";

export default class Status extends Component {
  constructor(arg) {
    super(arg);
    this.state = {
      status: [],
    };
    this.timer = null;
  }
  updateStats() {
    axios.get("/api/v1/rescue/status").then((resp) => {
      this.setState({
        status: resp.data.data,
      });
    });
  }
  componentDidMount() {
    this.updateStats();
    this.timer = setInterval(() => {
      this.updateStats();
    }, 60 * 1000);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  render() {
    var data = this.state.status;
    const newData = data.find((item) => item.status.trim() == "NEW");
    const doingData = data.find((item) => item.status.trim() == "DOING");
    const todoData = data.find((item) => item.status.trim() == "TODO");
    const completedData = data.find(
      (item) => item.status.trim() == "COMPLETED"
    );

    let newDataDisplay;
    if (newData) {
      newDataDisplay = (
        <div style={{ fontSize: "30px", color: "#DF695A" }}>
          {newData?.total}
        </div>
      );
    } else {
      newDataDisplay = (
        <div style={{ fontSize: "30px", color: "#DF695A" }}>0</div>
      );
    }

    let doingDataDisplay;
    if (doingData) {
      doingDataDisplay = (
        <div style={{ fontSize: "30px", color: "#48B545" }}>
          {doingData?.total}
        </div>
      );
    } else {
      doingDataDisplay = (
        <div style={{ fontSize: "30px", color: "#48B545" }}>0</div>
      );
    }

    let todoDataDisplay;
    if (todoData) {
      todoDataDisplay = (
        <div style={{ fontSize: "30px", color: "#3779FB" }}>
          {todoData?.total}
        </div>
      );
    } else {
      todoDataDisplay = (
        <div style={{ fontSize: "30px", color: "#3779FB" }}>0</div>
      );
    }

    let completedDataDisplay;
    if (completedData) {
      completedDataDisplay = (
        <div style={{ fontSize: "30px", color: "#44A0A6" }}>
          {completedData?.total}
        </div>
      );
    } else {
      completedDataDisplay = (
        <div style={{ fontSize: "30px", color: "#44A0A6" }}>0</div>
      );
    }
    return null;

    return (
      <div className="w3-row">
        <div className="w3-quarter w3-container" style={{ padding: "2px" }}>
          <div
            className="w3-row rh-status-item w3-margin-top w3-round-large"
            style={{ backgroundColor: "#FBECEA" }}
          >
            <div className="w3-half w3-container">
              <div className="tickets">
                <div className="tickets-font">
                  {newDataDisplay}
                  <div style={{ color: "#DF695A" }}>tickets</div>
                </div>
                <div className="tickets-people-count">
                  <div style={{ color: "#DF695A" }}>
                    <Link
                      style={{ textDecoration: "none"}}
                      to={`/requests/${newData?.status}`}
                    >
                      NEW
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="w3-half w3-container w3-margin-top">
              <img style={{ height: "40px" }} src={Newstatusimg}></img>
            </div>
          </div>
        </div>
        <div className="w3-quarter w3-container" style={{ padding: "2px" }}>
          <div
            className="w3-row rh-status-item w3-margin-top w3-round-large"
            style={{ backgroundColor: "#E1EBFE" }}
          >
            <div className="w3-half w3-container">
              <div className="tickets">
                <div className="tickets-font">
                  {todoDataDisplay}
                  <div style={{ color: "#3779FB" }}>tickets</div>
                </div>
                <div className="tickets-people-count">
                  <div style={{ color: "#3779FB" }}>
                    <Link
                      style={{ textDecoration: "none"}}
                      to={`/requests/${todoData?.status}`}
                    >
                      TODO
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="w3-half w3-container w3-margin-top">
              <img style={{ height: "40px" }} src={Todostatusimg}></img>
            </div>
          </div>
        </div>
        <div className="w3-quarter w3-container" style={{ padding: "2px" }}>
          <div
            className="w3-row rh-status-item w3-margin-top w3-round-large"
            style={{ backgroundColor: "#EAF5EA" }}
          >
            <div className="w3-half w3-container">
              <div className="tickets">
                <div className="tickets-font">
                  {doingDataDisplay}
                  <div style={{ color: "#48B545" }}>tickets</div>
                </div>
                <div className="tickets-people-count">
                  <div style={{ color: "#48B545" }}>
                    <Link
                      style={{ textDecoration: "none"}}
                      to={`/requests/${doingData?.status}`}
                    >
                      DOING
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="w3-half w3-container w3-margin-top">
              <img style={{ height: "40px" }} src={Doingstatusimg}></img>
            </div>
          </div>
        </div>
        <div className="w3-quarter w3-container" style={{ padding: "2px" }}>
          <div
            className="w3-row rh-status-item w3-margin-top w3-round-large"
            style={{ backgroundColor: "#EAF5F5" }}
          >
            <div className="w3-half w3-container">
              <div className="tickets">
                <div className="tickets-font">
                  {completedDataDisplay}
                  <div style={{ color: "#44A0A6" }}>tickets</div>
                </div>
                <div className="tickets-people-count">
                  <div style={{ color: "#44A0A6" }}>
                    <Link
                      style={{ textDecoration: "none"}}
                      to={`/requests/${completedData?.status}`}
                    >
                      COMPLETED
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="w3-half w3-container w3-margin-top">
              <img style={{ height: "40px" }} src={Completedstatusimg}></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
