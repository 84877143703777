import  React,{ Component } from 'react'; 
import { FormTextField,FormTextarea,GooglePlacesAutoComplete ,SelectField} from './../Common/Helper.js';  
import Reveal from './../Common/Reveal';
import axios from 'axios';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import GoogleMapWidget from './../Widgets/GoogleMap';
import { showMessage, hideMessage } from './../../redux/actions.js';

class AddHelpCentre extends Component{

    constructor(arg) {
        super(arg);
        this.state =   {
            place: null,
            setLocation: null,
            form: {},
            errors: {}, 
            successMessage: '',
            isSending: false,
        }
        this.handlePlaceChange = this.handlePlaceChange.bind(this);
    }

    componentDidMount() {

        this.getLocation();
    }

    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition.bind(this));
        } else { 
            console.error("Geolocation is not supported by this browser.");
        }
    }

    showPosition(pos) {
        this.state.form.location_lat = pos.coords.latitude;
        this.state.form.location_lon = pos.coords.longitude;
        this.setState({
            setLocation: {
                lat: pos.coords.latitude,
                lon: pos.coords.longitude
            }
        });
    }

    locationSelect(lat,lon, geoLocation) {
        this.state.form.location_lat = lat;
        this.state.form.location_lon = lon;
        let newLocation = {
            lat: lat,
            lon: lon
        };
        if(geoLocation) {
            let location = geoLocation.address_components.filter(item=>item.types.indexOf('sublocality') > -1)
                .map(item=>item.long_name)
                .join(',');
            newLocation.place_id = geoLocation.place_id;
            newLocation.formatted_address = geoLocation.formatted_address;
            newLocation.location = location;
        }
        this.setState({
            setLocation: newLocation,
        });
    }

    handlePlaceChange(place){ 
        if (!place.geometry) {
          return;
        }
        this.state.form.address_components  = place.address_components;
        this.setState({
            place:place
        });
    }

    changeFormValue(name,value) {
        this.state.form[name] = value;
    }
    
    handleSubmit() {
        const {isSending} = this.state;

        if (isSending) {
            return;
        }
        //converting the date format to 'yyyy-mm-dd'
        let today = new Date()
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd
        
        
        let formData = Object.assign({},this.state.form); 
        let errors = {};
        
        if (!formData.contactName) {
            errors['contactName'] = "Name is required"
        }
        if (!formData.phoneNumber) {
            errors['phoneNumber'] = "Phone Number is required"
        }
        else if (formData.phoneNumber && formData.phoneNumber.length != 10){
            errors['phoneNumber'] = "Invalid Phone Number"
        }
        if (!formData.type) {
            errors['type'] = "Service Type is required"
        }
        if (!formData.district) {
            errors['district'] = "District is required"
        }
        if (!formData.address) {
            errors['address'] = "Address is required"
        } 
        if (!formData.serviceEndDate) {
            errors['serviceEndDate'] = "Service End Date is required"
        }
        if (formData.serviceEndDate < today){
            errors['serviceEndDate'] = "Please choose a valid date"
        }
        if (!formData.peopleCount) {
            errors['peopleCount'] = "People Count is required"
        }

        if (Object.keys(errors).length != 0) {
            this.setState({
                errors: errors
            });
            return false;
        }

        this.setState({
            isSending: true
        });
        axios.post('/api/v1/service-provider/add',formData)
        .then(resp=> {
            resp = resp.data;
            if (!resp.meta.success) {
                // this.setState({errors: resp.data});
                this.props.showMessage('fail', resp.meta.message);
            } else {
                this.setState({errors: ''});
                this.props.showMessage('success', "Service Infomation added successfully.");
                this.props.hideModal(); 
            }
            this.setState({
                isSending: false
            });
        });
        return false;
    }

    render () {
        const { t } = this.props;
        const { districts } = this.props
        var clsSuccess = 'hidden';
        if (this.state.successMessage != '') {
            clsSuccess = '';
        }
        var googlePlace = this.state.setLocation && this.state.setLocation.location ? this.state.setLocation.location : '';

        return (
            <Reveal onClose={this.props.hideModal} >
               <div className="w3-container ">   
                    <h4 className="w3-center w3-margin" style={{paddingBottom: "10px"}}>
                        <div className="w3-center">
                            <div className="w3-row">{t("serviceProvider")}</div>
                        </div>
                    </h4> 
                    <form className="w3-row-padding">
                        <div className="l4 s12 w3-col">
                            <FormTextField 
                                label={t("contactName")}
                                placeholder="Contact Name"
                                name="contactName"
                                isMandatory="true"
                                inputClass="w3-input w3-border w3-round-large"
                                value = {this.state.form.contactName}
                                valueChange={this.changeFormValue.bind(this)}
                                errors = {this.state.errors.contactName} /> 
                        </div>
                        <div className="l4 s12 w3-col">
                            <FormTextField
                                label={t("phoneNumber_ServiceProvider")}
                                placeholder="Phone Number"
                                name="phoneNumber"
                                isMandatory="true"
                                type="number"
                                value = {this.state.form.phoneNumber}
                                inputClass="w3-input w3-border w3-round-large"
                                valueChange={this.changeFormValue.bind(this)}
                                errors = {this.state.errors.phoneNumber} />
                        </div>

                        <div className="l4 s12 w3-col">   
                            <SelectField
                                label={t("serviceType")}
                                placeholder="Type of help"
                                name="type"
                                selectClass="w3-select w3-border w3-round-large"
                                isMandatory="true"
                                value = {this.state.form.type}
                                valueChange={this.changeFormValue.bind(this)}
                                errors = {this.state.errors.type}>
                                <option value=""> - Select Service Type - </option>
                                {this.props.issuetypes.map(item =>{
                                    return <option value={item.value}>{t(item.label)}</option>
                                })}
                            </SelectField> 
                        </div>

                        <div className="w3-col l6 s12 " id="location">
                            <label className="w3-margin-bottom">
                                {t("markLocation")}</label>
                            <GooglePlacesAutoComplete
                                albumLocation={googlePlace}
                                onPlaceChange={place => this.handlePlaceChange(place)}
                                placeholder = "Location" />

                            <div className="w3-row">
                                <GoogleMapWidget mapStyle={{height: '250px'}} 
                                    lat={this.state.form.location_lat}
                                    lon={this.state.form.location_lon}
                                    setLocation={this.state.setLocation}
                                    place={this.state.place}
                                    mapId='google-map-safe'
                                    locationSelect={this.locationSelect.bind(this)}/>
                            </div>
                        </div>

                        <div className="w3-col l6 s12">
                            <SelectField
                                label={t("District")}
                                place="district"
                                name="district"
                                selectClass="w3-select w3-border w3-round-large"
                                isMandatory="true"
                                value = {this.state.form.district}
                                valueChange={this.changeFormValue.bind(this)}
                                errors = {this.state.errors.district}>
                                <option value="">- Select District -</option>
                                {this.props.districts.map(item => (<option key={item} value={item}>{item}</option>))}
                            </SelectField> 

                            <FormTextarea 
                                name="address"
                                label={t("Address")}
                                placeholder="Address" 
                                isMandatory="true"
                                inputClass="w3-input w3-border w3-round-large" 
                                valueChange={this.changeFormValue.bind(this)}
                                value = {this.state.form.address}
                                errors = {this.state.errors.address}
                                type="text" />

                            <FormTextField
                                label={t("srviceEndDate")}
                                name="serviceEndDate"
                                isMandatory="true"
                                type="date"
                                value = {this.state.form.serviceEndDate}
                                inputClass="w3-input w3-border w3-round-large"
                                valueChange={this.changeFormValue.bind(this)}
                                errors = {this.state.errors.serviceEndDate} />

                            <FormTextField
                                label={t("peopleThatCanBeServed")}
                                placeholder="Total number of people that can be served"
                                name="peopleCount"
                                isMandatory="true"
                                type="number"
                                value = {this.state.form.peopleCount}
                                inputClass="w3-input w3-border w3-round-large"
                                valueChange={this.changeFormValue.bind(this)}
                                errors = {this.state.errors.peopleCount} />
 

                        </div>

                        <div className="w3-col" style={{paddingTop: '15px'}}>
                            <FormTextarea 
                                name="information"
                                label={t("MoreInfo")}
                                placeholder="More Information - About quantity / Time of availability" 
                                inputClass="w3-input w3-border w3-round-large" 
                                valueChange={this.changeFormValue.bind(this)}
                                value = {this.state.form.information}
                                type="text" />
                        </div>

                        <div className={"m12 s12 w3-col w3-center w3-text-green" + clsSuccess} role="alert">{this.state.successMessage}</div>
                        <div className="w3-panel m12 s12 w3-col">
                            <div className="w3-right">
                                <button type="button" className="w3-button w3-dark-grey" onClick={this.props.hideModal}>{t("cancel")}</button>
                                <button type="button" className="w3-button w3-margin-left" style={{backgroundColor:'#fcc512'}}
                                    onClick={this.handleSubmit.bind(this)}
                                    disabled={(this.state.isSending)?true:false} >{t("submit")}</button>
                            </div>
                        </div>
                    </form>
                </div>   
            </Reveal>
        )
    }
} 


function mapStateToProps(state) {
    return {
        requestTypeList:state.requestTypeList,
        districts: state.districts,
        issuetypes: state.issueTypes,
    }
}
export default connect(mapStateToProps, { 
    showMessage
})(withTranslation()(AddHelpCentre));
