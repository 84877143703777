import React from "react"
import { Footer } from "./Common/Footer"
import Header from "./Common/Header"

const PrivacyPolicy = () => (
  <div>
    <Header />
    <div className="main-privacy w3-content">
      <div className="cgs-privacy-policy">
        <h4 className="w3-center w3-padding-16">Privacy Policy</h4>
        <div className="w3-padding">
          <p className="cgs-agreement-desc ">
            This page informs you of our policies regarding the collection, use,
            and disclosure of personal data when you use our Service, as well as
            the options you have over such data. Your data is used to offer and
            improve the Service. You consent to the collection and use of
            information in line with this policy by using the Service. Unless
            otherwise stated in this Privacy Policy, terms used in this Privacy
            Policy have the same meanings as those found in our Terms and
            Conditions, which may be accessed by clicking{" "}
            {/* <Link to="/terms-of-use">here</Link>. */}
          </p>
        </div>
        <div className="w3-padding">
          <p className="cgs-agreement-title">INFORMATION COLLECTION AND USE</p>
          <p className="cgs-agreement-desc ">
            We collect several different types of information for various purposes
            to provide and improve our Service to you.
          </p>
        </div>
        <p className="w3-padding">TYPES OF DATA COLLECTED</p>
        <div className="w3-padding">
          <p className="cgs-agreement-title">PERSONAL DATA</p>
          <p className="cgs-agreement-desc ">
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </p>
          <div className="w3-padding cgs-agreement-desc">
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Cookies and Usage Data</li>
              <li>Usage Data</li>
            </ul>
          </div>
          <p className="cgs-agreement-desc">
            We may also collect information on how the Service is accessed and
            used ("Usage Data"). This Usage Data may include information such as
            your computer's Internet Protocol address (e.g. IP address), browser
            type, browser version, the pages of our Service that you visit, the
            time and date of your visit, the time spent on those pages, unique
            device identifiers and other diagnostic data.
          </p>
        </div>
        <div className="w3-padding">
          <p className="cgs-agreement-title">DATA ON TRACKING AND COOKIES</p>
          <p className="cgs-agreement-desc ">
            We use cookies and other tracking technologies to monitor and record
            activities on our Service. Cookies are little data files that may
            contain an anonymous unique identification. Cookies are little text
            files that are transmitted to your browser from a website and kept on
            your device. Beacons, tags, and scripts are other tracking
            technologies used to collect and track information and to develop and
            analyze our Service. You may tell your browser to reject all cookies
            or to notify you when one is received. However, if you do not accept
            cookies, you may be unable to utilize certain aspects of our Service.
          </p>
        </div>
        <div className="w3-padding">
          <p className="cgs-agreement-title">COOKIE EXAMPLES WE USE:</p>
          <div className="w3-padding cgs-agreement-desc">
            <ul>
              <li>
                Session cookies- Session Cookies are used to operate our Service.
              </li>
              <li>
                Preference Cookies- Preference Cookies are used to remember your
                preferences and other settings.
              </li>
              <li>
                Security Cookies- Security Cookies are used for security purposes.
              </li>
            </ul>
          </div>
          <p className="cgs-agreement-title">DATA APPLICATION</p>
          <p className="cgs-agreement-desc ">
            Ceegees uses the information collected for a variety of purposes,
            including:
          </p>
          <div className="w3-padding cgs-agreement-desc">
            <ul>
              <li>To provide and keep the Service running.</li>
              <li>To inform you about changes to our Service</li>
              <li>
                To enable you to participate in interactive parts of our Service
                if you so want.
              </li>
              <li>To provide customer care and support</li>
              <li>
                To give analysis or valuable information in order to enhance the
                Service
              </li>
              <li>To keep track of how the Service is being used</li>
              <li>Detecting, preventing, and resolving technical difficulties</li>
              <li>Transfer Of Data</li>
            </ul>
          </div>
          <p className="cgs-agreement-desc ">
            Your information, including Personal Data, may be transmitted to and
            stored on computers located outside of your state, province, nation,
            or other governmental jurisdiction where data protection regulations
            differ from those in your jurisdiction. If you live outside of India
            and want to give us information, please be aware that we will transmit
            the data, including Personal Data, to India and process it there. Your
            agreement to that transfer is represented by your approval to this
            Privacy Policy, followed by your submission of such information.
            Ceegees will take all reasonable steps to ensure that your data is
            treated securely and in accordance with this Privacy Policy, and no
            transfer of your Personal Data to an organization or country will take
            place unless adequate controls, including the security of your data
            and other personal information, are in place.
          </p>
        </div>
        <div className="w3-padding">
          <p className="cgs-agreement-title">DISCLOSURE OF DATA</p>
          <p className="cgs-agreement-title">LEGAL REQUIREMENTS</p>
          <div className="w3-padding cgs-agreement-desc">
            <ul>
              <li>To comply with a legal obligation</li>
              <li>To protect and defend the rights or property of Ceegees </li>
              <li>
                To prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li>
                To protect the personal safety of users of the Service or the
                public
              </li>
              <li>To protect against legal liability</li>
            </ul>
          </div>
          <p className="cgs-agreement-title">SECURITY OF DATA</p>
          <p className="cgs-agreement-desc ">
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially acceptable
            means to protect your Personal Data, we cannot guarantee its absolute
            security.
          </p>
          <br />
          <p className="cgs-agreement-title">PROVIDERS OF SERVICES</p>
          <p className="cgs-agreement-desc ">
            Third-party organizations and persons ("Service Providers") may be
            employed to enable our Service, deliver the Service on our behalf,
            conduct Service-related tasks, or assist us in analyzing how our
            Service is utilized. These third parties have access to your Personal
            Data only to carry out these duties on our behalf and are not
            permitted to disclose or use it for any other reason.
          </p>
          <br />
          <p className="cgs-agreement-title">ANALYTICS</p>
          <p className="cgs-agreement-desc ">
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
          <br />
          <p className="cgs-agreement-title">GOOGLE ANALYTICS</p>
          <p className="cgs-agreement-desc ">
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected to
            track and monitor the use of our Service. This data is shared with
            other Google services. Google may use the collected data to
            contextualize and personalize the ads of its own advertising network.
            You can opt-out of having made your activity on the Service available
            to Google Analytics by installing the Google Analytics opt-out browser
            add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
            analytics.js, and dc.js) from sharing information with Google
            Analytics about visits activity. For more information on the privacy
            practices of Google, please visit the Google Privacy & Terms web page:{" "}
            <a href="https://policies.google.com/privacy?hl=en">
              https://policies.google.com/privacy?hl=en
            </a>
          </p>
        </div>
        <div className="w3-padding">
          <p className="cgs-agreement-title">LINKS TO OTHER SITES</p>
          <p className="cgs-agreement-desc ">
            Our Service may contain connections to websites that we do not
            control. When you click on a third-party link, you will be sent to the
            third- party's website. We strongly recommend that you read the
            Privacy Policies of any website you visit. We have no control over,
            and accept no responsibility for, any third-party website or services'
            content, privacy policies, or practices.
          </p>
        </div>
        <div className="w3-padding">
          <p className="cgs-agreement-title">CHILDREN PRIVACY</p>
          <p className="cgs-agreement-desc ">
            Our Service is not intended for anybody under the age of 18.
            ("Children"). We do not collect personally identifying information
            from children under the age of 18. Please contact us if you are a
            parent or guardian and are aware that your children have given us with
            Personal Data. If we discover that we collected Personal Data from
            children without parental agreement, we take measures to erase that
            information from our servers.
          </p>
        </div>
        <div className="w3-padding">
          <p className="cgs-agreement-title">CHANGES TO THIS PRIVACY POLICY</p>
          <p className="cgs-agreement-desc ">
            We reserve the right to change our Privacy Policy at any moment. Any
            changes will be communicated to you by publishing the revised Privacy
            Policy on this website and sending you an email. Prior to the
            modification being effective, we will notify you through email and/or
            a prominent notice on our Service, and we will revise the "effective
            date" at the top of this Privacy Policy. It is recommended that you
            examine this Privacy Policy on a regular basis for any updates. When
            changes to this Privacy Policy are posted on this page, they become
            effective.
          </p>
        </div>
        <div className="w3-padding">
          <p className="cgs-agreement-title">CONTACT US</p>
          <p className="cgs-agreement-desc ">
            If you have any queries regarding our Privacy Statement, please email
            us at <a href="mailto:mail@ceegees.in ">mail@ceegees.in </a>
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
)
export default PrivacyPolicy
