import React from 'react';
import { withTranslation } from 'react-i18next';
class FilterSelect extends React.Component {

    render() {
        const { t } = this.props;
        const optionList = [];
        if (this.props.name != 'Sort On'){
            optionList.push(<option value="" key="nothin">All</option>);
        }
        const { filterOptions } = this.props; 
        if (Array.isArray(filterOptions)) {
            filterOptions.map((item, key) => { 
                optionList.push(
                    <option key={item.value} value={item.value}>{t(item.name)}</option> 
                );
            });
        } else if (!Array.isArray(filterOptions) && typeof(filterOptions) === "object") {
            Object.keys(filterOptions).forEach((key) => {
                optionList.push(
                    <option key={key} > {filterOptions[key]}</option>
                );
            });
        }
        // if (this.props.name == 'Request Types'){
        //     optionList.push(
        //         <option key="rescue_request"  value="rescue_request"></option>);
        // }

        return  <div style={{marginBottom:"6px"}}>
            <label>{this.props.name}</label>
        <select onChange={this.props.handleFilterData}  className="w3-select w3-round w3-border" > 
            {optionList}
        </select>
        </div>
    }
}

export default ((withTranslation()(FilterSelect)))