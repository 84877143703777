import React, { Component } from 'react';
import { Spinner } from '../Common/Helper';
import axios from 'axios';
import Certificate from './Certificate';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Leaderboard extends Component {
  constructor(arg) {
    super(arg);
    this.state = {
      data: null,
      page: 1,
    };
    //this.handleCertificate = this.handleCertificate.bind(this);
  }

  loadData(page = 1) {
    if (page < 0) {
      return;
    }
    axios
      .get('/api/v1/heros', {
        params: {
          page: page,
        },
      })
      .then((resp) => { 
        this.setState({
          data: resp.data,
          page,
        });
      });
  }

  componentDidMount() {
    this.loadData(1);
  }

  pageChange(change) {
    this.loadData(this.state.page + change);
  }

  //   handleCertificate = (details) => {
  //     const newWindow = window.open(`/certificate/${details.id}`, '_blank');
  //     newWindow.document.title = 'Certificate';
  //     newWindow.document.body.innerHTML = '<div id="certificate"></div>';
  //     ReactDOM.render(<Certificate data={details} />, newWindow.document.getElementById('certificate'));
  //   };

  render() {
    let content = <Spinner />;
    const { t } = this.props
    const { data, page } = this.state;
    if (data) {
      content = (
        <div className='w3-container w3-center'>
          {data.map((item, idx) => {
            return (
              <Link key={item.id}
                className='leader-board-row'
                to={{
                  pathname: `/hero/${item.id}`,
                  state: {
                    details: item
                  }
                }}
                style={{ cursor: 'pointer', textDecoration: 'none' }}
              >
                <span className='row-item' style={{ width: '40px' }}>
                  <img src={item.picture} style={{ width: '32px', height: '32px' }} />

                </span>
                <span className='row-item lb-name '>{item.name}</span>
                <span className='row-item'>
                  <span className='leader-board-total'>{item.total}</span>
                </span>
              </Link>
            );
          })}

        </div>
      );
    }

    return (
      <div className=' w3-white w3-small  w3-margin-top w3-round-large' style={{ height: '500px', overflowY: 'scroll' }}>

        <div className='w3-row'>
          <div className='w3-left'>
          <h6 className='w3-padding w3-bar-item' style={{ color: '#919191',margin:'0px' }}>
            Helping Heros
          </h6>
          </div>
          <div className='w3-right w3-padding'>
            {page > 1 && (
              <button className='w3-button w3-left w3-tiny w3-padding-smal' onClick={this.pageChange.bind(this, -1)}>
                Prev
              </button>
            )}
            {data?.length == 10 && (
              <button className='w3-button w3-right w3-tiny' onClick={this.pageChange.bind(this, 1)}>
                Next
              </button>
            )}
          </div>
        </div>
        {content}
      </div>
    );
  }
}

export default (withTranslation()(Leaderboard))
