import Footerimg from '../../images/footer.png'
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const {t} = useTranslation()
    return <div><section className='w3-black w3-container w3-padding-32'>
        <div className='w3-content w3-row  '>
            <div className='w3-col s12 m4 l4'>
                <img src={Footerimg}></img>
            </div>
            <div className="w3-col s12 m8 l8">
                <div className=' w3-col s4 m4 l4 footer-links'>
                    <a href='mailto:rescuehands@ceegees.in'>{t("contactUs")}</a>
                    <a target="_blank" href='https://rescuehands.in'>{t("website")}</a>  
                    <a target="_blank" href='https://blog.rescuehands.in/'>{t("blog")}</a>
                    </div>
                
                <div className=' w3-col s4 m4 l4  footer-links'>
                    <a target="_blank" href='/privacy'>{t("privacyPolicy")}</a>
                    <a target="_blank" href='/terms'>{t("termsOfService")}</a>
                    <a target="_blank" href='https://forms.gle/gfEHDdd9mnAUHzxa8'>{t("Feedback")}</a>
                    
                </div>
                <div className='w3-col s4 m4 l4 '>

                    <div className='w3-row'>
                        <div className='w3-col m12 s12 w3-center w3-margin-bottom'>
                            <div style={{ color: '#9a9c9a' }}>{t("followUs")}</div>
                        </div>
                        <div className=' w3-col s4 w3-center '>
                            <a href='https://discord.gg/9WRQQX86tF'>
                                <i className='fab fab fa-discord w3-xlarge'></i></a>
                        </div>
                        <div className=' w3-col s4  w3-center' >
                            <a href='https://twitter.com/ceegees_inc'>
                                <i className='fab  fa-twitter-square w3-xlarge  '></i></a>
                        </div>
                        <div className=' w3-col s4  w3-center'>
                            <a href='https://in.linkedin.com/company/ceegees-soft'>
                                <i className='fa-brands fa-linkedin w3-xlarge'></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        <section className='section7 w3-container w3-row w3-center'>
            <div className=''>
                <p>Copyright © Rescuehand.in, 2022. All Rights Reserved  .  Created by Ceegees Software Solutions</p>
            </div>
        </section>
    </div>
}