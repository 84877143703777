import axios from "axios";


export const appMessage = (text,status = true) => {
    return showMessage(status?'success':'error',text);
}

export const metaMessage = (meta)=>{ 
    return appMessage(meta.message,meta.success);
}

export const getSessionUser = () => (dispatch) =>  { 
    return axios.get('/auth/user').then((resp)=>{
        if (resp.data.data.user) {
            dispatch({
                type:'SESSION_USER',
                data: resp.data.data.user
            });
        } else {
            dispatch({
                type:'SESSION_USER',
                data: null
            });
        }
    });
}

export const showModal = (name,data=null) => {
    return {
        type: "MODAL_UPDATE", 
        name:name,
        data: data
    };
}

export const setFilterStats = (data) => {
    return {
        type: "REQUEST_FILTER_STATS", 
        data: data
    };
}

export const showMessage = (type, text,stay=0) => {
    return {
        type: "MESSAGE_STATUS",
        key: 'appMessage',
        value: {
            stay:stay,
            cls : type == 'success'?'w3-green':'w3-red',
            text,
        },
    };
}

export const setSearch = (text) => {

    return {
        type:'SEARCH_TEXT',
        value:text
    }
}

export const getLatLng = (item) => {
    let lat = null;
    let lng = null;
     if (item.json && item.json.location){
        lat = parseFloat(""+item.json.location.lat);
        lng = parseFloat(""+item.json.location.lon);
    } else if(item.latLng && item.latLng.coordinates && item.latLng.coordinates.length == 2) {
        lat = item.latLng.coordinates[0];
        lng = item.latLng.coordinates[1];
    } 
    return {
        lat:lat,
        lng:lng
    }
}
export const hideMessage = () => {
    return {
        type: "MESSAGE_STATUS",
        key: 'appMessage',
        value: {
            cls: '',
            text: '',
        },
    };
};

export const setData = (key,value) => {
    return {
        type: "SET_DATA",
        key,
        value
    }
}
