import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEn from './locales/en/translation';




export const initLang = (lng, translation) => {
  const res = {
    en: {
      translation: translationsEn
    },
    [lng]: {
      translation
    },
  }
  i18n
    //.use(LanguageDetector)
    .use(initReactI18next)
    .init({
      //react-i18next options
      react: {
        wait: true
      },
      resources: res,
      fallbackLng: lng,
      debug: true,
      lng,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      }
    });
}

i18n
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //react-i18next options
    partialBundledLanguages: true,
    react: { suspense: true },
    resources: {},
    fallbackLng: 'en',
    debug: true,
    ns: [],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

