import React from "react";
import axios from 'axios';


class Createmission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {

            },
            errors: null
        }
    }

    changeFormFieldValue = (event) => {
        const { name, value } = event.target;
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    handleFormSubmit = () => {
        var formData = this.state.form;

        axios.post('/api/v1/mission/add', formData)
            .then(resp => {
                resp = resp.data;
                //ToDo -Change later
                if (!resp.meta.success) {
                    alert("failed")
                } else {
                    alert("success")
                }
            });

    }

    render() {
        return (
            <div className="w3-panel w3-topbar w3-bottombar w3-border-yellow w3-padding-32">
                <section className="w3-container w3-align-left">

                </section>
                <section className="w3-container w3-align-left">
                    <div><h6>Mission Details</h6></div>
                </section>
                <section className="mission-details w3-row">
                    <div className="w3-container w3-half">
                        <div className="w3-container">
                            <div className="w3-padding-16"><input name="name" className="w3-input w3-border w3-white" type="text" placeholder="Mission Name" value={this.state.form.name} onChange={this.changeFormFieldValue}></input></div>
                            <div className="w3-padding-16"><input name="description" className="w3-input w3-border w3-white" type="text" placeholder="Description" value={this.state.form.description} onChange={this.changeFormFieldValue}></input></div>
                            <div className="w3-padding-16"><input name="location" className="w3-input w3-border w3-white" type="text" placeholder="Location" value={this.state.form.location} onChange={this.changeFormFieldValue}></input></div>
                            <div className="w3-padding-16"><input name="geography" className="w3-input w3-border w3-white" type="text" placeholder="Geographic boundary , center and radius" value={this.state.form.geography} onChange={this.changeFormFieldValue}></input></div>
                            <div>
                                <select style={{ marginRight: '10px' }} name="language" value={this.state.form.language} onChange={this.changeFormFieldValue} className=" mission-details w3-padding">
                                    <option>Language</option>
                                    <option>Malayalam</option>
                                    <option>Assamese</option>
                                </select>
                                <select className="w3-padding" name="timezone" value={this.state.form.timezone} onChange={this.changeFormFieldValue}>
                                    <option>Timezone</option>
                                    <option>Timezone1</option>
                                    <option>Timezone2</option>
                                </select>
                            </div>
                            <h6>Ticket Status</h6>
                            <p>When different people raise rescue requests, what all are the steps the requests should flows through for completion.</p>
                            <div><button style={{ marginBottom: '-13px' }} className="w3-button w3-black w3-round-large">ToDo</button></div>
                            <div><button style={{ marginBottom: '-13px' }} className="w3-button w3-black w3-round-large">Doing</button></div>
                            {/* <div><button style={{marginBottom:'-13px'}} className="w3-button w3-white w3-border w3-round-large">+ Add more</button></div> */}
                            <div><button className="w3-button w3-black w3-round-large">Completed</button></div>
                        </div>
                    </div>
                    <div className="w3-container w3-half ">
                        <div className="w3-padding-16"><input name="hashtags" className="w3-input w3-border w3-white" type="text" placeholder="Hashtags related to the incident" value={this.state.form.hashtags} onChange={this.changeFormFieldValue}></input></div>
                        <div className="w3-padding-16"><input name="tagline" className="w3-input w3-border w3-white" type="text" placeholder="Tagline" value={this.state.form.tagline} onChange={this.changeFormFieldValue}></input></div>
                        <div className="w3-padding-16"><input name="url" className="w3-input w3-border w3-white" type="text" placeholder="Page url / Subdomain" value={this.state.form.url} onChange={this.changeFormFieldValue}></input></div>
                        <h6>How are we solving the problem?</h6>
                        <ul>
                            <li>Check the tickets and see if the locations is marked correctly on map.</li>
                            <li>Check if the tickets are having the needs Marked correcty.</li>
                            <li>Check if the tickets are having the needs Marked correcty.</li>
                            <li>Coordinate with on field Volunteer to make sure help is reaching the needy.</li>
                        </ul>
                        <button style={{ marginBottom: "12px" }} className="w3-button w3-amber w3-round w3-right" onClick={this.handleFormSubmit}>Create A New Rescue Mission</button>
                    </div>
                </section>

            </div>
        )
    }
}

export default Createmission