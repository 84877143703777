
import { NavLink, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSearch } from '../../redux/actions';
import AddRequestModal from './../Request/AddModal';
import RequestDetailModal from './../Request/Details';
import Reveal from './../Common/Reveal';
import { showModal, getSessionUser, setData } from './../../redux/actions.js';
import FilterSelect from './FilterSelect'
import moment from 'moment';
import { withTranslation } from 'react-i18next';



class SubHeader extends Component {
    constructor(arg) {
        super(arg);
        let end = moment().endOf('day').add(1, 'minute');


        let tm = new Date();
        const timeList = [];
        let endStr = 'Now';
        let endRange = moment();

        for (var idx = 0; idx < 12; idx++) {
            end = end.subtract(6, "Hours");

            if (end < tm) {
                timeList.push({
                    name: end.format('Do hh a') + " - " + endStr,
                    value: `${endRange.valueOf()}-${end.valueOf()}`
                });
                endRange = end.clone();
                endStr = endRange.format('Do hh a');


            }
        }

        timeList.push({
            name: 'older',
            value: `${moment().subtract(3650, 'days').valueOf()}-${end.valueOf()}`
        });
        this.state = {
            mobileMenu: 'w3-hide',
            modalContent: null,
            modal: null,
            filterLabels: {
                sort: 'Sort',
                time: 'Time',
                severity: 'Severity All',
                district: 'District All',
                type: 'Request Type'
            },
            filterData: {},
            districtList: [],
            sortConfig: [
                {
                    type: 'sort',
                    value: 'DESC',
                    name: 'Newest First',
                },
                {
                    type: 'sort',
                    value: 'ASC',
                    name: 'Oldest First',
                }
            ],
            timeConfig: timeList,
            timerange: {
                from: null,
                to: null
            },
        }
    }
    togggleMobile() {
        this.setState({ mobileMenu: (this.state.mobileMenu === 'w3-hide') ? 'w3-show' : 'w3-hide' })
    }
    componentDidMount() {
        this.props.setSearch('');
        this.props.getSessionUser();
    }

    hideModal() {

        this.props.showModal('empty');
    }

    showModal(name, data) {
        let content = null;
        if (name === 'add_request') {
            content = <AddRequestModal hideModal={this.hideModal.bind(this)} />;
        } else if (name === 'update_request') {
            content = <Reveal onClose={this.hideModal.bind(this)} >
                <RequestDetailModal hideModal={this.hideModal.bind(this)}
                    id={data.id} />
            </Reveal>
        }
        this.setState({ modalContent: content })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.modalInfo !== this.props.modalInfo) {
            this.showModal(nextProps.modalInfo.name,
                nextProps.modalInfo.data);
        }
    }


    changeSearch(e) {
        this.props.setSearch(e.target.value);
    }

    handleFilterData(type, e) {

        const { handleFilterData } = this.props;
        let { filterData } = this.state;
        filterData[type] = e.target.value;




        if (handleFilterData) {
            handleFilterData(filterData);
        }
    }

    handleTimeRange() {
        const { handleFilterData } = this.props;

        let { filterData, timerange } = this.state;

        if (timerange?.from && timerange?.to) {
            const startData = moment(timerange?.from).format('lll')
            const endData = moment(timerange?.to).format('lll')
            filterData.startAt = moment(startData).valueOf();
            filterData.endAt = moment(endData).valueOf();


            if (handleFilterData) {
                handleFilterData(filterData);
            }

        }

    }

    timerangeInput = (event) => {
        const { name, value } = event.target

        this.setState({
            timerange: {
                ...this.state.timerange,
                [name]: value
            }
        }, () => this.handleTimeRange())
    }



    render() {
        const { severityList, searchText, issueTypes, filterStats, t } = this.props;
        return <div className="kf-header-2 w3-row" >

            <div className="w3-left">
                {this.props.statusList.map(item => {
                    return <NavLink key={item.key}
                        activeClassName="active"
                        className={`w3-bar-item w3-button w3-small ${item.cls}`}
                        to={`/${this.props.path}/${item.key}`}>
                        {item.title}
                    </NavLink>
                })}
                <NavLink
                    activeClassName="active"
                    exact={true}
                    className={`w3-bar-item w3-button w3-small  `}
                    to={`/${this.props.path}/`}>All
                </NavLink>
                <div>
                    <button style={{ backgroundColor: '#fcc512' }}
                        className="w3-small w3-button  w3-hide-large w3-hide-medium w3-right"
                        onClick={this.togggleMobile.bind(this)}>Filter</button>
                </div>

            </div>
            <div className="w3-right"  >
                <button style={{
                    backgroundColor: '#fcc512',
                    color: 'black',
                    margin: "4px 2px 8p 2px"
                }}
                    className="w3-bar-item w3-button w3-round "
                    onClick={e => this.showModal('add_request')}>{t("createRequest")}</button>
            </div>
            <div className={`w3-bar-block w3-margin-top w3-hide-large w3-hide-medium ${this.state.mobileMenu}`}>
            </div>
            {this.state.modalContent}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        statusList: state.statusList,
        authUser: state.authUser,
        modalInfo: state.modalInfo,
        issueTypes: state.issueTypes,
        severityList: state.severityList,
        districts: state.districts,
        filterStats: state.requestFilterStats,
    }
}
export default withRouter(connect(mapStateToProps, {
    setSearch,
    getSessionUser,
    showModal,
    setData
})(withTranslation()(SubHeader)));